<template>
  <div class="fixed top-0 bottom-0 z-10 text-gray-800">
    <div
      class="shadow-inner fixed left-0 top-0 w-full h-full z-10 bg-white flex flex-col"
    >
      <div class="bg-indigo-500 w-full flex flex-row">
        <div
          class="mx-5 cursor-pointer w-1/2 my-4"
          @click="$emit('hideEditDay')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            class="fill-current text-white"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
            />
          </svg>
        </div>
        <div class="w-1/2 text-right flex flex-row justify-end pt-2">
          <div class="cursor-pointer flex flex-col w-20" @click="downloadIcs()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              class="fill-current text-white text-center mx-auto"
            >
              <g><rect fill="none" height="24" width="24" /></g>
              <g><path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" /></g>
            </svg>
            <span class="text-xs text-white">
              {{ $t("daySettings.downloadIcs") }}</span
            >
          </div>
          <div
            class="cursor-pointer flex flex-col w-20 mx-5"
            @click="removeVote()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              class="fill-current text-white text-center mx-auto"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"
              />
            </svg>
            <span class="text-xs text-white">
              {{ $t("daySettings.removeVote") }}</span
            >
          </div>
        </div>
      </div>
      <div class="w-full px-5 py-3 bg-gray-100">
        <p class="font-semibold">
          <TimeText :time="this.day.date" :future="true" />
        </p>
      </div>
      <div v-if="showDownloadError" class="p-5 text-red-500 text-sm">
        {{ $t("daySettings.downloadError") }}
      </div>
      <div class="px-5 py-5">
        <div class="flex flex-col text-gray-800">
          <div class="flex flex-col mt-1 text-sm">
            <p>{{ $t("daySettings.dayTitle") }}</p>
            <input
              autocomplete="off"
              type="text"
              name="username"
              v-model="day.dayTitle"
              class="rounded-lg border-gray-500"
              minlength="5"
            />
          </div>
          <div class="flex flex-row mt-3">
            <div class="flex flex-col w-1/2 pr-3 text-sm">
              <p>{{ $t("daySettings.startTime") }}</p>
              <input
                autocomplete="off"
                type="time"
                name="username"
                v-model="day.startTime"
                class="rounded-lg border-gray-500"
                minlength="5"
              />
            </div>
            <div class="flex flex-col w-1/2 pl-3 text-sm">
              <p>{{ $t("daySettings.endTime") }}</p>
              <input
                autocomplete="off"
                type="time"
                name="username"
                v-model="day.endTime"
                class="rounded-lg border-gray-500"
                minlength="5"
              />
            </div>
          </div>
          <div class="flex flex-row">
            <button
              class="mr-3 max-w-xs w-2/3 mx-auto flex flex-row text-center justify-center px-4 py-3 my-3 mt-5 text-sm text-indigo-500 transition-colors duration-150 bg-indigo-100 rounded-full focus:shadow-outline hover:bg-indigo-200 shadow-md border-2 border-indigo-300"
              @click="$emit('hideEditDay')"
            >
              {{ $t("app.cancel") }}
            </button>
            <button
              @click="save()"
              class="ml-3 max-w-xs w-2/3 mx-auto flex flex-row text-center justify-center justify-items-center px-4 py-3 my-3 mt-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
            >
              {{ $t("app.save") }}
            </button>
          </div>
        </div>
      </div>
      <strong class="my-2 mx-5 text-lg">{{ $t("app.users") }}</strong>
      <div
        v-for="user in this.day.users"
        :key="user.uid"
        class="flex flex-row mx-5 py-3 border-b border-gray-200"
      >
        <ProfileImage :name="user.displayName" :status="user.status" />
        <p class="ml-3 pt-2">{{ user.displayName }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileImage from "@/components/ProfileImage.vue";
const ics = require("ics");
var FileSaver = require("file-saver");
import firebase from "firebase/app";
import "firebase/analytics";
import TimeText from "@/components/TimeText.vue";
export default {
  name: "EditDay",
  components: { TimeText, ProfileImage },
  props: {
    day: {},
  },
  data() {
    return {
      showDownloadError: false,
    };
  },
  computed: {
    calendar() {
      return this.$store.state.activeCalendar;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    save() {
      firebase.analytics().logEvent("save_day_settings");
      this.$emit("saveDay", this.day);
    },
    removeVote() {
      this.$emit("removeVote", this.day);
    },
    downloadIcs() {
      if (this.day.startTime && this.day.endTime) {
        this.showDownloadError = false;
        firebase.analytics().logEvent("download_ics");
        const date = new Date(this.day.date);
        ics.createEvent(
          {
            title: this.calendar.name,
            description: this.calendar.name + " - " + this.day.dayTitle,
            busyStatus: "FREE",
            start: [
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate(),
              parseInt(this.day.startTime.substring(0, 2)),
              parseInt(this.day.startTime.substring(3, 5)),
            ],
            end: [
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate(),
              parseInt(this.day.endTime.substring(0, 2)),
              parseInt(this.day.endTime.substring(3, 5)),
            ],
          },
          (error, value) => {
            var blob = new Blob([value], {
              type: "text/calendar;charset=utf-8",
            });
            FileSaver.saveAs(blob, "event.ics");
          }
        );
      } else {
        this.showDownloadError = true;
      }
    },
  },
};
</script>
