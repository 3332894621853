<template>
  <div class="flex flex-col text-center p-5 mt-10">
    <div>
      {{$t("invitationError.message1")}}
    </div>
    <div class="mt-10 p-5 bg-gray-200 rounded-2xl">
      <p class="text-sm">
        {{$t("invitationError.message2")}}
      </p>
      <router-link
        to="/login"
        class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
      >
        <p class="font-semibold">{{$t("app.login")}} / {{$t("app.register")}}</p>
        <p class="text-sm">{{$t("app.itsfree")}}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvitationError",
  data() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>
