<template>
  <div v-if="this.calendar">
    <div class="flex flex-col p-10 pt-3 text-center text-gray-700">
      <strong class="my-2">{{ $t("invite.invite") }}</strong>
      <p>{{ $t("invite.invitemessage") }}</p>
      <WebShare :url="inviteUrl" :title="calendar.name" />
      <p>
        {{ $t("invite.explanation") }}
      </p>
      <strong class="my-2 text-lg">{{ $t("app.users") }}</strong>
      <div v-for="user in calendar.users" :key="user.uid">
        <CalendarUser :user="user" />
      </div>
      <div
        @click="$emit('hideCalendarUsers')"
        class="max-w-xs w-2/3 mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-500 transition-colors duration-150 bg-indigo-100 rounded-full focus:shadow-outline hover:bg-indigo-200 shadow-md border-2 border-indigo-300 cursor-pointer"
      >
        {{ $t("app.close") }}
      </div>
    </div>
  </div>
</template>
<script>
  import WebShare from "@/components/WebShare.vue";
  import CalendarUser from "@/components/CalendarUser.vue";
  export default {
    name: "CalendarUsers",
    components: { WebShare, CalendarUser },
    props: {},
    data() {
      return {
        deleteUser: false,
      };
    },
    computed: {
      calendar() {
        return this.$store.state.activeCalendar;
      },
      inviteUrl() {
        return (
          window.location.origin +
          (this.calendar.options.allowAnonymousUsers
            ? "/invite/g/"
            : "/invite/") +
          (this.calendar.key ? this.calendar.key : this.calendar[".key"])
        );
      },
      myUser() {
        return this.$store.state.user;
      },
    },
    methods: {},
  };
</script>
