<template>
  <div class="border-t p-3 text-left flex flex-col">
    <div class="flex flex-row">
      <div class="w-1/4">
        <ProfileImage
          :name="user.displayName"
          :status="user.status"
          class="-ml-3"
        />
      </div>
      <div class="w-3/4">
        <p>{{ user.displayName }}</p>
      </div>
      <div
        v-show="user.uid != myUser.uid && !myUser.isAnonymous"
        @click="deleteUser = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          class="fill-current text-gray-500"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"
          />
        </svg>
      </div>
    </div>
    <div class="flex flex-col">
      <p v-if="user.isAnonymous" class="text-xs">
        {{ $t("app.guestuser") }}
      </p>
      <p v-if="user.lastLogin" class="text-xs text-right">
        {{ $t("app.lastActivity") }}: <TimeText :time="user.lastLogin" />
      </p>
    </div>
    <div v-if="deleteUser" class="flex flex-row">
      <button
        class="mr-3 max-w-xs w-2/3 mx-auto flex flex-row text-center justify-center px-4 py-3 my-5 text-sm text-indigo-500 transition-colors duration-150 bg-indigo-100 rounded-full focus:shadow-outline hover:bg-indigo-200 shadow-md border-2 border-indigo-300"
        @click="deleteUser = false"
      >
        {{ $t("app.cancel") }}
      </button>
      <button
        @click="removeUserFromCalendar"
        class="ml-3 max-w-xs w-2/3 mx-auto flex flex-row text-center justify-center justify-items-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
      >
        {{ $t("app.delete") }}
      </button>
    </div>
  </div>
</template>
<script>
import TimeText from "@/components/TimeText.vue";
import ProfileImage from "@/components/ProfileImage.vue";
export default {
  name: "CalendarUser",
  components: { TimeText, ProfileImage },
  props: { user: Object },
  data() {
    return {
      deleteUser: false,
    };
  },
  computed: {
    calendar() {
      return this.$store.state.activeCalendar;
    },
    inviteUrl() {
      return window.location.origin + "/invite/" + this.calendar[".key"];
    },
    myUser() {
      return this.$store.state.user;
    },
  },
  methods: {
    removeUserFromCalendar() {
      this.$store.dispatch("deleteUserFromCalendar", this.user);
      this.deleteUser = false;
    },
  },
};
</script>
