<template>
  <div class="flex flex-col text-center p-5 mt-10">
    <div class="text">
      {{ $t("invite.message") }}
    </div>
    <div class="mt-10 p-5 bg-gray-200 rounded-2xl">
      <p class="text-sm">
        {{ $t("invite.create") }}
      </p>
      <router-link
        to="/login"
        class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
      >
        <p class="font-semibold">
          {{ $t("app.login") }} / {{ $t("app.register") }}
        </p>
        <p class="text-sm">{{ $t("app.itsfree") }}</p>
      </router-link>
    </div>
    <div class="mt-5" v-if="allowGuest">
      <p class="text-sm">{{ $t("invite.or") }}</p>
      <router-link
        to="/login-anonymously"
        class="max-w-xs mx-auto flex flex-col text-center justify-center px-6 py-3 m-2 text-sm text-indigo-700 transition-colors duration-150 bg-indigo-100 rounded-full focus:shadow-outline hover:bg-indigo-200 border-indigo-300 border-2"
      >
        <p class="font-semibold">{{ $t("invite.asguest") }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Invite",
    props: {
      allowGuest: Boolean,
    },
    metaInfo() {
      return {
        title: "Invitation to Lets Join",
        meta: [
          {
            content:
              "Someone has invited you to the group calendar on Let's Join",
          },
          { robots: "noindex,nofollow" },
        ],
        htmlAttrs: {
          lang: "en",
          amp: true,
        },
      };
    },
    mounted() {
      if (this.$route.params.calendarId) {
        if (!this.user || this.user.uid) {
          localStorage.invitation = this.$route.params.calendarId;
        }
        if (this.user) {
          this.$router.push({
            name: "Home",
          });
        }
      }
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
    },
    watch: {
      user() {
        if (this.user) {
          this.$router.push({
            name: "Home",
          });
        }
      },
    },
  };
</script>
