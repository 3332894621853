<template>
  <div class="lang-dropdown">
    <select v-model="lang" class="w-full rounded-lg border-gray-500">
      <option
        v-for="(lang, i) in languageArray"
        :key="`lang${i}`"
        :value="lang"
      >
        {{ $t("lang." + lang) }}
      </option>
    </select>
  </div>
</template>
<script>
import { languages } from "@/plugins/i18n";
export default {
  data() {
    return {
      languageArray: languages,
    };
  },
  computed: {
    lang: {
      get: function() {
        return this.$store.state.locale;
      },
      set: function(newVal) {
        this.$store.dispatch("changeLocale", newVal);
      },
    },
  },
};
</script>
