<template>
  <div id="app">
    <NavBar />
    <div class="container mx-auto">
      <div style="padding-top: 64px">
        <router-view />
      </div>
    </div>
    <footer>
      <cookie-law theme="dark-lime" :buttonText="$t('app.cookielawgotit')">
        <div slot="message">
          {{ $t("app.cookielaw") }}
        </div>
      </cookie-law>
    </footer>
    <InstallPrompt
      v-if="showInstallPrompt"
      @closeInstallPrompt="closeInstallPrompt"
    />
  </div>
</template>
<script>
import "./assets/styles/tailwind.css";
import NavBar from "@/components/NavBar.vue";
import InstallPrompt from "@/components/InstallPrompt.vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { db, messaging } from "./db";
import _ from "lodash";
import CookieLaw from "vue-cookie-law";
export default {
  name: "Login",
  components: { NavBar, CookieLaw, InstallPrompt },
  data() {
    return {
      showInstallPrompt: false,
    };
  },
  created() {
    var appMode = "browser";
    if (
      document.referrer.startsWith("android-app://") ||
      navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      appMode = "pwa";
    }
    firebase.analytics().logEvent("launch_" + appMode);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch("setUser", {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          isAnonymous: user.isAnonymous,
          emailVerified: user.emailVerified,
          metadata: user.metadata,
          providerData: user.providerData,
        });
        if (
          user.providerData &&
          user.providerData[0] &&
          user.providerData[0].providerId === "password" &&
          !user.emailVerified &&
          user.metadata.creationTime === user.metadata.lastSignInTime
        ) {
          user.sendEmailVerification();
        }
        const isStandalone = window.matchMedia("(display-mode: standalone)")
          .matches;
        if (
          !document.referrer.startsWith("android-app://") &&
          !(navigator.standalone || isStandalone)
        ) {
          var date = new Date();
          const lastWeek = new Date(date.setDate(date.getDate() - 7));
          if (
            this.user &&
            new Date(this.user.metadata.creationTime) < lastWeek &&
            (!localStorage.lastInstallPrompt ||
              new Date(localStorage.lastInstallPrompt) < lastWeek)
          ) {
            this.showInstallPrompt = true;
          }
        }
      }
    });
    if (firebase.messaging && firebase.messaging.isSupported()) {
      //if (this.dbUser.fcmToken) {
      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
        if (this.dbUser) {
          if (!this.dbUser.messages) {
            this.dbUser.messages = [];
          }
          this.dbUser.messages.push({
            time: new Date().toISOString(),
            title: this.$t(payload.notification.title),
            content: this.$t(payload.notification.body),
          });
          this.$store.dispatch("setDbUser", this.dbUser);
        }
      });
    } else {
      console.log("messaging not supported");
    }
  },
  computed: {
    buildDate() {
      return document.documentElement.dataset.buildTimestampUtc;
    },
    user() {
      return this.$store.state.user;
    },
    dbUser() {
      return this.$store.state.dbUser;
    },
    isActiveUser() {
      return (
        this.user &&
        ((this.user.email && this.user.emailVerified) || this.user.isAnonymous)
      );
    },
    calendar() {
      return this.$store.state.activeCalendar;
    },
  },
  methods: {
    async loadUserData() {
      if (this.user) {
        await this.initiateDbuser();
        await this.handleInvitation();
        await this.handleReplaceAnonymousUser();
        this.getFcmToken();
      }
    },
    async handleInvitation() {
      const invitation =
        !localStorage.invitation ||
        localStorage.invitation == false ||
        localStorage.invitation == "false"
          ? false
          : localStorage.invitation;
      if (this.user.uid && invitation && this.dbUser) {
        var calendar = await this.getCalendar(invitation);
        if (calendar) {
          if (
            this.user.isAnonymous &&
            calendar.options &&
            !calendar.options.allowAnonymousUsers
          ) {
            firebase.analytics().logEvent("invitation_noguest");
            this.$router.push({
              name: "InvitationError",
            });
          } else {
            calendar.key = invitation;
            await this.$store.dispatch("bindCalendar", invitation);
            this.$store.dispatch("setActiveCalendar", calendar);
            this.$store.dispatch("mapCalendarAndUser", {
              calendar,
              dbUser: this.dbUser,
            });
            localStorage.invitation = false;
            firebase.analytics().logEvent("invitation_success");
          }
        } else {
          firebase.analytics().logEvent("invitation_error");
          this.$router.push({
            name: "InvitationError",
          });
        }
      }
    },
    async handleReplaceAnonymousUser() {
      if (this.calendar) {
        if (localStorage.replaceAnonymousUser) {
          var userToReplace = JSON.parse(
            JSON.stringify(localStorage.replaceAnonymousUser)
          );
        }
        if (
          userToReplace &&
          this.calendar.users &&
          this.calendar.users[userToReplace]
        ) {
          firebase.analytics().logEvent("replace_guest_user");
          this.$store.dispatch(
            "deleteUserFromCalendar",
            this.calendar.users[userToReplace]
          );
          localStorage.replaceAnonymousUser = false;
        }
      }
    },
    async initiateDbuser() {
      const snapshot = await db.ref("users/" + this.user.uid).once("value");
      var dbUser = snapshot.val();
      if (dbUser) {
        if (!("autoDecline" in dbUser)) dbUser.autoDecline = true;
        if (!("pushChanges" in dbUser)) dbUser.pushChanges = true;
        if (!("pushNewUsers" in dbUser)) dbUser.pushNewUsers = true;
        if (!("pushAgreeOnDate" in dbUser)) dbUser.pushAgreeOnDate = true;
        if (dbUser.locale != this.$i18n.locale)
          dbUser.locale = this.$i18n.locale;
      }
      if (!_.isEqual(dbUser, this.dbUser)) {
        if (dbUser) {
          dbUser.lastLogin = new Date().toISOString();
          this.$store.dispatch("setDbUser", dbUser);
        } else {
          dbUser = {
            displayName: this.user.displayName,
            isAnonymous: this.user.isAnonymous,
            autoDecline: true,
            pushChanges: true,
            pushNewUsers: true,
            pushAgreeOnDate: true,
            locale: this.$i18n.locale,
            lastLogin: new Date().toISOString(),
            messages: [
              {
                time: new Date().toISOString(),
                title: this.$t("messages.welcome.title"),
                content: this.$t("messages.welcome.text"),
              },
            ],
            calendars: [],
          };
          this.$store.dispatch("setDbUser", dbUser);
        }
        await this.$store.dispatch("uploadDbUser");
      }
    },
    async getCalendar(calendarId) {
      const payload = await db.ref("calendars/" + calendarId).once("value");
      if (payload.exists()) {
        var calendar = payload.val();
        calendar.key = calendarId;
        return calendar;
      }
      return false;
    },
    getFcmToken() {
      if (messaging && this.dbUser) {
        messaging
          .getToken({
            vapidKey:
              "BAOIgyqjnddOKi3KmDfP_KiBREQ6BOTA2Byw7KzHui4OdeZUECPEtgSQfP8kze7Q80C4si3wZ8qXC6X6Ram5wno",
          })
          .then((currentToken) => {
            if (currentToken) {
              if (!this.dbUser.fcmTokens) this.dbUser.fcmTokens = [];
              if (this.dbUser.fcmTokens.indexOf(currentToken) === -1) {
                firebase.analytics().logEvent("cfm_token_store");
                this.dbUser.fcmTokens.push(currentToken);
                this.$store.dispatch("setDbUser", this.dbUser);
                this.$store.dispatch("uploadDbUser");
              }
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log(err);
            firebase.analytics().logEvent("cfm_token_error");
          });
      }
    },
    closeInstallPrompt() {
      this.showInstallPrompt = false;
    },
  },
  watch: {
    user() {
      this.loadUserData();
    },
    dbUser() {
      this.loadUserData();
    },
  },
};
</script>
