<template>
  <div class="flex flex-col text-center p-5 mt-10">
    <div class="font-bold">
      Support & Feedback
    </div>
    <p class="mt-10">{{ $t("support.message") }}</p>
    <div class="mt-10 p-5 bg-gray-200 rounded-2xl">
      <p>
        Email:
        <a
          class="cursor-pointer text-indigo-500"
          href="mailto=info@sauer-medientechnik.de"
          >info@sauer-medientechnik.de</a
        >
      </p>
      <div v-if="user && !user.isAnonymous">
        <p>
          WhatsApp:
          <a
            class="cursor-pointer text-indigo-500"
            href="https://api.whatsapp.com/send?phone=+4915172729617"
            >Click me</a
          >
        </p>
      </div>
    </div>
    <div class="text-xs mt-1">Build Date: {{ buildDate }}</div>
  </div>
</template>

<script>
export default {
  name: "Support",
  data() {
    return {};
  },
  mounted() {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    buildDate() {
      return document.documentElement.dataset.buildTimestampUtc;
    },
  },
};
</script>
