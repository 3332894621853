<template>
  <div v-if="editCalendar">
    <div class="flex flex-col p-10 pt-3 text-center text-gray-700">
      <div class="flex justify-center flex-col">
        <h2 class="text-xl font-bold">{{ $t("calset.settings") }}</h2>
        <strong class="my-2">{{ $t("calset.name") }}</strong>
        <input
          autocomplete="off"
          type="text"
          name="username"
          v-model="editCalendar.name"
          class="rounded-lg border-gray-300"
          minlength="5"
        />
        <strong class="mt-2">{{ $t("calset.guest") }}</strong>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.allowAnonymousUsers"
          />
          <span class="ml-2">{{ $t("calset.allowguest") }}</span>
        </label>
      </div>
      <div class="flex justify-center flex-col bg-gray-100 rounded-lg p-5 mt-5">
        <strong class="mb-2">{{ $t("calset.visibledays") }}</strong>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.weekdays.Monday"
          />
          <span class="ml-2">{{ $t("app.monday") }}</span>
        </label>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.weekdays.Tuesday"
          />
          <span class="ml-2">{{ $t("app.tuesday") }}</span>
        </label>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.weekdays.Wednesday"
          />
          <span class="ml-2">{{ $t("app.wednesday") }}</span>
        </label>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.weekdays.Thursday"
          />
          <span class="ml-2">{{ $t("app.thursday") }}</span>
        </label>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.weekdays.Friday"
          />
          <span class="ml-2">{{ $t("app.friday") }}</span>
        </label>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.weekdays.Saturday"
          />
          <span class="ml-2">{{ $t("app.saturday") }}</span>
        </label>
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="editCalendar.options.weekdays.Sunday"
          />
          <span class="ml-2">{{ $t("app.sunday") }}</span>
        </label>
      </div>
      <div class="flex flex-row justify-center mt-5 ">
        <button
          @click="$emit('hideCalendarSettings')"
          class="px-6 py-3 m-2 text-sm text-indigo-700 transition-colors duration-150 bg-indigo-100 rounded-full focus:shadow-outline hover:bg-indigo-200 border-indigo-300 border-2"
        >
          {{ $t("app.cancel") }}
        </button>
        <button
          @click="save"
          class="px-5 py-3 m-2 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-full focus:shadow-outline hover:bg-indigo-800"
        >
          {{ $t("app.save") }}
        </button>
      </div>
      <strong class="my-2 text-red-500">{{ $t("app.dangerzone") }}</strong>
      <p>{{ $t("calset.deletemessage") }}</p>
      <input
        autocomplete="off"
        type="text"
        name="confirmDelete"
        @keyup="$data.confirmDelete = $event.target.value"
        class="rounded-lg border-gray-500"
        minlength="5"
      />
      <button
        @click="deleteCalendar"
        class="max-w-xs w-2/3 mx-auto disabled:opacity-50 px-4 py-3 mt-5 m-2 text-sm text-red-100 transition-colors duration-150 bg-red-500 rounded-full focus:shadow-outline hover:bg-red-800"
        :disabled="!(confirmDelete == 'DELETE')"
      >
        {{ $t("app.delete") }}
      </button>
      <div
        @click="$emit('hideCalendarSettings')"
        class="max-w-xs w-2/3 mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-500 transition-colors duration-150 bg-indigo-100 rounded-full focus:shadow-outline hover:bg-indigo-200 shadow-md border-2 border-indigo-300"
      >
        {{ $t("app.close") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CalendarSettings",
  props: {},
  data() {
    return {
      editCalendar: false,
      confirmDelete: "",
    };
  },
  mounted() {
    this.editCalendar = this.calendar;
  },
  computed: {
    calendar() {
      return this.$store.state.activeCalendar;
    },
    dbUser() {
      return this.$store.state.dbUser;
    },
  },
  methods: {
    save() {
      this.$store.dispatch("updateCalendarName", this.editCalendar.name);
      this.$store.dispatch("uploadCalendarName");
      this.$store.dispatch("updateCalendarOptions", this.editCalendar.options);
      this.$store.dispatch("uploadCalendarOptions");
      this.showSettings = false;
      this.$emit("hideCalendarSettings");
    },
    async deleteCalendar() {
      if (this.confirmDelete == "DELETE") {
        const calendar = this.calendar;
        const calendarId = calendar.key ? calendar.key : calendar[".key"];
        this.$store.dispatch("deleteCalendar", calendarId);
        await this.$store.dispatch("uploadDeleteCalendar", calendarId);
        var updatedDbUser = { ...this.dbUser };
        if (updatedDbUser.calendars) {
          var i = updatedDbUser.calendars.indexOf(calendarId);
          if (i >= 0) {
            updatedDbUser.calendars.splice(i, 1);
          }
          this.$store.dispatch("setDbUser", updatedDbUser);
          this.$store.dispatch("uploadDbUser");
        }
        this.$router.push({
          name: "Home",
        });
      }
    },
  },
};
</script>
