<template>
  <div class="text-justify p-5">
    <h1 class="font-bold text-lg my-5">Legal Notice</h1>
    <p>
      Lets Join is build and operated privately without commercial interest by:
    </p>
    <p class="mt-3">Valentin Sauer</p>
    <p>Eisenkramergasse 1</p>
    <p>82362 Weilheim</p>
    <p>Germany</p>
    <p class="mt-3">
      Email:
      <a class="text-indigo-700" href="mailto:info@sauer-medientechnik.de"
        >info@sauer-medientechnik.de</a
      >
    </p>
  </div>
</template>

<script>
  export default {
    name: "LegalNotice",
  };
</script>
