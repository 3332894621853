<template>
  <div class="relative antialiased">
    <div v-if="calendar">
      <div
        v-if="!showSettings && !showInvitations && calendar && calendar.options"
      >
        <div
          class="pt-5 px-5 pb-3 w-full rounded-b-3xl text-indigo-100 bg-gradient-to-b from-gray-800 to-indigo-900 shadow"
        >
          <div class="flex flex-row">
            <div
              class="absolute left-0 top-5 pl-6 cursor-pointer"
              @click="showInvitations = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                class="fill-current text-indigo-100"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                />
              </svg>
            </div>
            <div
              class="absolute right-0 top-5 pr-6 cursor-pointer"
              @click="showSettings = true"
              v-if="!this.user.isAnonymous"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                class="fill-current text-indigo-100"
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none" />
                  <path
                    d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="text-sm text-indigo-100 text-center px-10">
            <div v-if="userNumber === 1 && !this.user.isAnonymous">
              <p>
                {{ $t("calendar.onlyuser") }}
              </p>
            </div>
            <p v-else class="py-1">
              {{ $t("calendar.thiscalendarhas") }} {{ userNumber }}
              {{ $t("app.users") }}.
            </p>
            <p>
              {{ $t("app.lastupdate") }}:
              <TimeText :time="calendar.lastUpdate" />
            </p>
          </div>
        </div>
        <div class="mt-5">
          <p class="text-gray-800 text-center text-sm mb-5">
            {{ $t("calendar.swiperight") }}<br />
            {{ $t("calendar.swipeleft") }}<br />
            {{ $t("calendar.longtap") }}
          </p>
          <ul id="daylist">
            <li v-for="day in calendar.days" :key="day.date">
              <DayCard
                :day="day"
                @updateDays="updateDays"
                @editDay="editDay"
                v-if="day.date >= new Date().toISOString().split('T')[0]"
              />
            </li>
          </ul>
        </div>
        <EditDay
          :day="dayToEdit"
          v-if="dayToEdit"
          @saveDay="saveDay"
          @hideEditDay="dayToEdit = false"
          @removeVote="removeVote"
        />
      </div>
      <CalendarSettings
        v-if="
          showSettings &&
            calendar &&
            calendar.options &&
            calendar.options.weekdays
        "
        @hideCalendarSettings="showSettings = false"
      />
      <CalendarUsers
        v-if="showInvitations"
        @hideCalendarUsers="showInvitations = false"
      />
    </div>
    <div v-else class="flex h-screen"><CssLoader class="m-auto" /></div>
  </div>
</template>

<script>
import { db } from "../db";
import CssLoader from "@/components/CssLoader.vue";
import TimeText from "@/components/TimeText.vue";
import CalendarSettings from "@/components/CalendarSettings.vue";
import CalendarUsers from "@/components/CalendarUsers.vue";
import DayCard from "@/components/DayCard.vue";
import EditDay from "@/components/EditDay.vue";
import firebase from "firebase/app";
import "firebase/analytics";
export default {
  name: "Calendar",
  components: {
    CssLoader,
    DayCard,
    EditDay,
    CalendarSettings,
    CalendarUsers,
    TimeText,
  },
  data() {
    return {
      dayToEdit: false,
      showSettings: false,
      showInvitations: false,
    };
  },
  metaInfo() {
    return {
      title: this.calendar ? this.calendar.name : "Lets Join",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  async mounted() {
    if (this.calendar) {
      await this.$store.dispatch("unbindCalendar", this.calendar.key);
    }
    await this.$store.dispatch("bindCalendar", this.$route.params.calendarId);
    firebase.analytics().logEvent("open_calendar");
    this.$store.dispatch(
      "setActiveCalendar",
      await this.getCalendar(this.$route.params.calendarId)
    );
    if (
      !this.calendar.days ||
      !this.daysFuture ||
      (this.calendar.days && this.daysFuture.length <= 30)
    ) {
      this.generateDays();
    }
    if (!this.calendar.options) {
      this.$store.dispatch("updateCalendarOptions", {
        allowAnonymousUsers: false,
        weekdays: {
          Monday: true,
          Tuesday: true,
          Wednesday: true,
          Thursday: true,
          Friday: true,
          Saturday: true,
          Sunday: true,
        },
      });
      this.$store.dispatch("uploadCalendarOptions");
    }

    var calendarUsers = this.calendar.users;
    if (
      this.calendar.users[this.user.uid].displayName != this.user.displayName
    ) {
      calendarUsers[this.user.uid].displayName = this.user.displayName;
      this.$store.dispatch("updateCalendarUsers", calendarUsers);
      this.$store.dispatch("uploadCalendarUsers");
    }
    if (
      this.calendar.users[this.user.uid].lastLogin != new Date().toISOString()
    ) {
      calendarUsers[this.user.uid].lastLogin = new Date().toISOString();
      this.$store.dispatch("updateCalendarUsers", calendarUsers);
      this.$store.dispatch("uploadCalendarUsers");
    }
  },
  computed: {
    calendar() {
      return this.$store.state.activeCalendar;
    },
    user() {
      return this.$store.state.user;
    },
    invitation() {
      return this.$store.state.invitation;
    },
    userNumber() {
      var count = 0;
      if (this.calendar) {
        for (var k in this.calendar.users) if (k) count++;
      }
      return count;
    },
    daysPast() {
      if (this.calendar && this.calendar.days) {
        var daysFuture = [];
        var date = new Date();
        date = new Date(date.setDate(date.getDate() - 1));
        for (var dayId in this.calendar.days) {
          if (
            this.calendar.days[dayId] &&
            new Date(this.calendar.days[dayId].date) < date
          ) {
            daysFuture.push(this.calendar.days[dayId]);
          }
        }
        return daysFuture;
      }
      return false;
    },
    daysFuture() {
      if (this.calendar && this.calendar.days) {
        var daysFuture = [];
        var date = new Date();
        for (var dayId in this.calendar.days) {
          if (
            this.calendar.days[dayId] &&
            new Date(this.calendar.days[dayId].date) > date
          ) {
            daysFuture.push(this.calendar.days[dayId]);
          }
        }
        return daysFuture;
      }
      return false;
    },
  },
  methods: {
    editDay(day) {
      this.dayToEdit = day;
    },
    generateDays() {
      var days = this.calendar.days;
      if (
        !days ||
        (!(typeof days === "object" || typeof days === "function") &&
          days !== null)
      ) {
        days = {};
      }
      var date = new Date();
      date = new Date(date.setDate(date.getDate() - 1));
      for (var i = 0; i < 30; i++) {
        var dateString = new Date(
          date.setDate(date.getDate() + 1)
        ).toISOString();
        if (!days[dateString.split("T")[0]]) {
          days[dateString.split("T")[0]] = {
            date: dateString,
          };
        }
      }

      date = new Date();
      var yesterday = new Date(date.setDate(date.getDate() - 1));
      for (var dayId in this.calendar.days) {
        if (
          this.calendar.days[dayId] &&
          new Date(this.calendar.days[dayId].date) < yesterday
        ) {
          delete this.calendar.days[dayId];
        }
      }
      this.$store.dispatch("updateDays", { days, change: false });
      this.$store.dispatch("uploadDays");
    },
    getDay(date) {
      if (this.calendar.days && this.calendar.days.length) {
        this.calendar.days.forEach((day) => {
          if (day.date == date) return day;
        });
      }
    },
    updateDays(days) {
      this.$store.dispatch("updateDays", {
        days,
        change: true,
      });
    },
    removeVote() {
      var days = this.calendar.days;
      var day = this.calendar.days[this.dayToEdit.date];
      if (day.users && day.users[this.user.uid]) {
        delete day.users[this.user.uid];
        this.$store.dispatch("updateDays", { days, change: true });
      }
      this.dayToEdit = false;
    },
    saveDay(updatedDay) {
      var days = this.calendar.days;
      var day = this.calendar.days[this.dayToEdit.date.split("T")[0]];
      if (updatedDay.dayTitle) day.dayTitle = updatedDay.dayTitle;
      if (updatedDay.startTime) day.startTime = updatedDay.startTime;
      if (updatedDay.endTime) day.endTime = updatedDay.endTime;
      this.$store.dispatch("updateDays", { days, change: true });
      this.dayToEdit = false;
    },
    async getCalendar(calendarId) {
      const payload = await db.ref("calendars/" + calendarId).once("value");
      if (payload.exists()) {
        var calendar = payload.val();
        calendar.key = calendarId;
        return calendar;
      }
      return false;
    },
  },
};
</script>
