<template>
  <div class="flex flex-col">
    <section id="firebaseui-auth-container" class="mt-10"></section>
    <div class="p-8 text-center text-gray-800">
      <p>
        {{ $t("login.text1") }}
      </p>
      <p class="text-xs mt-3">
        {{ $t("login.agree") }}
        <router-link to="/dataprotection" class="text-indigo-700">{{
          $t("login.privacypolicy")
        }}</router-link
        >.
      </p>
    </div>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  import "firebase/analytics";
  import * as firebaseui from "firebaseui";
  import "firebaseui/dist/firebaseui.css";
  export default {
    name: "Login",
    props: {
      msg: String,
    },
    mounted() {
      if (localStorage.replaceAnonymousUser) {
        var userToReplace = JSON.parse(
          JSON.stringify(localStorage.replaceAnonymousUser)
        );
      }
      if (userToReplace) {
        if (this.user && this.user.isAnonymous) {
          this.user.delete();
          this.$store.dispatch("setDbUser", false);
          this.$store.dispatch("setUser", false);
        }
      }

      firebase.analytics().logEvent("login");
      firebase.analytics().logEvent("Signup");

      let ui = firebaseui.auth.AuthUI.getInstance();
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth());
      }
      var uiConfig = {
        signInSuccessUrl: "/",
        signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true,
          },
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
      };
      ui.start("#firebaseui-auth-container", uiConfig);
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
    },
  };
</script>
