<template>
  <center class="p-5">
    <h1 class="my-5 font-semibold">404 - Not Found!</h1>
    <p>
      It seems like you are lost! Did you follow a wrong link or did you receive
      a wrong invitation link? Just ask your friend to send you a new one!
    </p>
    <a href="/" class="text-indigo-500 py-5">Let's Join Homepage</a>
  </center>
</template>

<script>
  export default {
    name: "NotFound",
  };
</script>
