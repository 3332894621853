import Vue from "vue";
import { vuexfireMutations, firebaseAction } from "vuexfire";
import { db } from "../db";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import i18n, { selectedLocale } from "@/plugins/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: selectedLocale,
    user: false,
    dbUser: false,
    usermenu: false,
    navmenu: false,
    calendars: [],
    activeCalendar: false,
    invitation: false,
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale;
      state.dbUser.locale = newLocale;
    },
    setUser(state, value) {
      state.user = value;
    },
    setDbUser(state, value) {
      state.dbUser = value;
    },
    setUserMenu(state, value) {
      state.usermenu = value;
    },
    setNavMenu(state, value) {
      state.navmenu = value;
    },
    updateDays(state, value) {
      state.activeCalendar.days = value.days;
      if (value.change)
        state.activeCalendar.lastUpdate = new Date().toISOString();
    },
    updateCalendarName(state, value) {
      state.activeCalendar.name = value;
    },
    setCalendars(state, value) {
      state.calendars = value;
    },
    setActiveCalendar(state, value) {
      state.activeCalendar = value;
    },
    setInvitation(state, value) {
      state.invitation = value;
    },
    updateCalendarUsers(state, value) {
      state.activeCalendar.users = value;
    },
    addCalendarToMe(state) {
      if (!state.dbUser.calendars) state.dbUser.calendars = [];
      state.dbUser.calendars.push(state.activeCalender);
    },
    deleteUserFromCalendar(state, user) {
      var calendar = { ...state.activeCalendar };
      delete calendar.users[user.uid];
      if (calendar.days) {
        for (var dayId in calendar.days) {
          if (
            calendar.days[dayId].users &&
            calendar.days[dayId].users[user.uid]
          ) {
            delete calendar.days[dayId].users[user.uid];
          }
        }
      }
      this.dispatch("uploadDays");
    },
    deleteCalendar(state, value) {
      delete state.calendars[value];
      state.activeCalenar = false;
    },
    updateCalendarOptions(state, value) {
      state.activeCalendar.options = value;
    },
    ...vuexfireMutations,
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale; // important!
      commit("updateLocale", newLocale);
    },
    setUser(context, value) {
      context.commit("setUser", value);
    },
    setDbUser(context, value) {
      context.commit("setDbUser", value);
    },
    uploadDbUser: firebaseAction(({ state }) => {
      const user = { ...state.user };
      const dbUser = { ...state.dbUser };
      return db.ref("users/" + user.uid).set(dbUser);
    }),
    setUserMenu(context, value) {
      context.commit("setUserMenu", value);
    },
    setNavMenu(context, value) {
      context.commit("setNavMenu", value);
    },
    bindCalendar: firebaseAction(async ({ bindFirebaseRef }, value) => {
      // return the promise returned by `bindFirebaseRef`
      return await bindFirebaseRef(
        "activeCalendar",
        db.ref("calendars/" + value)
      );
    }),
    unbindCalendar: firebaseAction(async ({ unbindFirebaseRef }, value) => {
      unbindFirebaseRef("activeCalendar", db.ref("calendars/" + value));
    }),
    setActiveCalendar(context, value) {
      context.commit("setActiveCalendar", value);
    },
    setCalendars(context, value) {
      context.commit("setCalendars", value);
    },
    addCalendar: firebaseAction(async (context, value) => {
      // return the promise so we can await the write
      const calendar = {
        name: "No Name Calendar 🎮⚽🎲📅",
        creator: value.uid,
        users: {
          [value.uid]: value,
        },
        options: {
          allowAnonymousUsers: false,
          weekdays: {
            Monday: true,
            Tuesday: true,
            Wednesday: true,
            Thursday: true,
            Friday: true,
            Saturday: true,
            Sunday: true,
          },
        },
        days: {},
      };
      return await db.ref("calendars").push(calendar);
    }),
    updateDays(context, value) {
      context.commit("updateDays", value);
      this.dispatch("uploadDays");
    },
    uploadDays: firebaseAction(async ({ state, getters }) => {
      const calendar = { ...state.activeCalendar };
      var calendarId = getters.currentCalendarId;
      if (!calendar.lastUpdate) calendar.lastUpdate = new Date().toISOString();
      if (calendar.days) {
        await db
          .ref("calendars/" + calendarId + "/lastUpdate")
          .set(calendar.lastUpdate);

        return await db
          .ref("calendars/" + calendarId + "/days")
          .set(calendar.days);
      }
    }),
    updateCalendarName(context, value) {
      context.commit("updateCalendarName", value);
    },
    uploadCalendarName: firebaseAction(({ state, getters }) => {
      const calendar = { ...state.activeCalendar };
      var calendarId = getters.currentCalendarId;
      return db.ref("calendars/" + calendarId + "/name").set(calendar.name);
    }),
    uploadCalendarUsers: firebaseAction(({ state, getters }) => {
      const users = { ...state.activeCalendar.users };
      var calendarId = getters.currentCalendarId;
      return db.ref("calendars/" + calendarId + "/users").set(users);
    }),
    uploadCalendarOptions: firebaseAction(({ state, getters }) => {
      const options = { ...state.activeCalendar.options };
      var calendarId = getters.currentCalendarId;
      return db.ref("calendars/" + calendarId + "/options").set(options);
    }),
    setInvitation(context, value) {
      context.commit("setInvitation", value);
    },
    updateCalendarUsers(context, value) {
      context.commit("updateCalendarUsers", value);
    },
    addMeToCalendar(context) {
      var user = this.state.user;
      var dbUser = this.state.dbUser;
      if (!dbUser) {
        dbUser = {
          displayName: user.displayName,
          isAnonymous: user.isAnonymous,
          calendars: [],
        };
        this.dispatch("setDbUser", dbUser);
      }
      const me = {
        displayName: user.displayName,
        uid: user.uid,
        isAnonymous: user.isAnonymous,
      };
      context.commit("addUserToCalendar", me);
      this.dispatch("uploadDbUser");
      context.commit("addCalendarToMe");
      this.dispatch("uploadCalendarUsers");
    },
    mapCalendarAndUser(context, value) {
      var calendar = value.calendar;
      var dbUser = value.dbUser;
      const user = { ...this.state.user };
      var calendarId = calendar.key;
      if (!dbUser.calendars) dbUser.calendars = [];
      if (dbUser.calendars.indexOf(calendarId) === -1) {
        dbUser.calendars.push(calendarId);
        context.commit("setDbUser", dbUser);
        this.dispatch("uploadDbUser");
      }

      if (!calendar.users) calendar.users = {};
      if (!calendar.users[user.uid]) {
        calendar.users[user.uid] = {
          displayName: user.displayName,
          uid: user.uid,
          isAnonymous: user.isAnonymous,
        };
        context.commit("updateCalendarUsers", calendar.users);
        this.dispatch("uploadCalendarUsers");
      }
    },
    uploadDeleteCalendarFromUser: firebaseAction(async (context, uid) => {
      const snapshot = await db.ref("users/" + uid).once("value");
      if (snapshot.exists()) {
        const dbUser = snapshot.val();
        var i = dbUser.calendars.indexOf(context.getters.currentCalendarId);
        if (i >= 0) {
          dbUser.calendars.splice(i, 1);
          await db.ref("users/" + uid).set(dbUser);
        }
      }
    }),
    deleteCalendar(context, value) {
      context.commit("deleteCalendar", value);
    },
    uploadDeleteCalendar: firebaseAction(async (context, value) => {
      console.log("Deleting calendar " + value);
      return await db.ref("calendars/" + value).remove();
    }),
    uploadDeleteDbUser: firebaseAction(async (context, value) => {
      return await db.ref("users/" + value).remove();
    }),
    updateCalendarOptions(context, value) {
      context.commit("updateCalendarOptions", value);
    },
    deleteUserFromCalendar(context, user) {
      context.commit("deleteUserFromCalendar", user);
      this.dispatch("uploadCalendarUsers");
      this.dispatch("uploadDeleteCalendarFromUser", user.uid);
    },
  },
  getters: {
    currentCalendarId: (state) => {
      if (state.activeCalendar.key) return state.activeCalendar.key;
      if (state.activeCalendar[".key"]) return state.activeCalendar[".key"];
      return false;
    },
    locale: (state) => state.dbUser.locale,
  },
  plugins: [createPersistedState()],
});

/*

              */
