<template>
  <div>
    <div
      class="pt-10 px-5 pb-10 w-full rounded-b-3xl text-indigo-100 bg-gradient-to-b from-gray-800 to-indigo-500 shadow"
    >
      <div class="text-lg font-bold mb-3">Let's Join</div>
      <p class="pb-5 px-5">
        {{ $t("home.subtitle") }}
      </p>
      <div class="flex justify-center">
        <ul class="text-sm text-left list-disc px-5 max-w-xs md:max-w-md">
          <li>{{ $t("home.bullets.1") }}</li>
          <li>{{ $t("home.bullets.2") }}</li>
          <li>{{ $t("home.bullets.3") }}</li>
          <li>{{ $t("home.bullets.4") }}</li>
          <li>{{ $t("home.bullets.5") }}</li>
        </ul>
      </div>
      <div
        class="justify-center px-10 pt-5 hidden  lg:flex flex-row text-center"
      >
        <p
          class="flex justify-center mx-auto bg-gray-200 rounded-lg text-gray-800 py-5 px-10 border-white border-solid border-4"
        >
          {{ $t("app.desktopWarning") }}
        </p>
      </div>
      <router-link
        to="/login"
        class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 mt-10 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md  cursor-pointer"
      >
        <p class="font-semibold">
          {{ $t("app.login") }} / {{ $t("app.register") }}
        </p>
        <p class="text-sm">{{ $t("app.itsfree") }}</p>
      </router-link>
    </div>
    <div class="relative text-justify">
      <div class="relative mt-10 max-w-md mx-auto">
        <div class="relative" style="padding-bottom: 100%">
          <iframe
            src="https://www.youtube.com/embed/fGpgw7_XiXw"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            class="absolute inset-0 w-full h-full"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <p class="p-5 mt-5">
        {{ $t("home.text1") }}
      </p>
      <p class="p-5"><strong>Let's Join</strong> {{ $t("home.text2") }}</p>
    </div>
    <div>
      <p class="mb-3">{{ $t("home.step1") }}</p>
      <p class="text-lg font-bold text-gray-500">#1</p>
      <p>{{ $t("home.step2") }}</p>
      <p class="text-lg font-bold text-gray-500">#2</p>
      <p>{{ $t("home.step3") }}</p>
      <p class="text-lg font-bold text-gray-500">#3</p>
      <p>{{ $t("home.step4") }}</p>
    </div>
    <div class="flex flex-row justify-center mt-5 text-sm">
      <router-link to="/legalnotice" class="text-indigo-700 mx-2">{{
        $t("app.legalnotice")
      }}</router-link>
      <span class="text-indigo-700"> | </span>
      <router-link to="/dataprotection" class="text-indigo-700 mx-2 mb-5">{{
        $t("app.dataprotection")
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeHomepage",
  metaInfo() {
    return {
      title: "Let's Join - Find meeting-dates faster",
      meta: [
        {
          content:
            "Let's Join makes it easy to agree on meeting-dates in private groups",
        },
      ],
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
};
</script>
