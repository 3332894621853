<template>
  <span>
    {{ timeText }}
  </span>
</template>
<script>
export default {
  name: "TimeText",
  props: {
    time: String,
    future: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    timeText() {
      const then = new Date(this.time);
      if (then == "Invalid Date") return this.time;
      var now = new Date();
      if (this.future) {
        now = new Date(now.setDate(now.getDate() - 1));
      }
      const dif = then.getTime() - now.getTime();
      var differenceInDays = dif / (1000 * 3600 * 24);
      var differenceInMinutes = dif / 1000 / 60;
      var differenceInHours = dif / 1000 / 60 / 60;

      if (differenceInMinutes >= -1 && differenceInMinutes <= 0)
        return this.$t("time.justnow");
      if (differenceInMinutes >= -3 && differenceInMinutes <= -1)
        return this.$tc(
          "time.mago",
          Math.floor(Math.abs(differenceInMinutes - 1))
        );
      if (differenceInMinutes >= -60 && differenceInMinutes < -3)
        return this.$tc(
          "time.mago",
          Math.floor(Math.abs(differenceInMinutes - 1)),
          {
            count: Math.floor(Math.abs(differenceInMinutes - 1)),
          }
        );
      if (differenceInHours >= -3 && differenceInHours <= -1)
        return this.$tc(
          "time.hago",
          Math.floor(Math.abs(differenceInHours - 1))
        );
      if (differenceInHours >= -24 && differenceInHours < -3)
        return this.$tc(
          "time.hago",
          Math.floor(Math.abs(differenceInHours - 1)),
          {
            count: Math.floor(Math.abs(differenceInHours - 1)),
          }
        );
      if (differenceInDays <= -1) return this.$t("time.yesterday");
      if (differenceInDays <= 1) return this.$t("time.today");
      if (differenceInDays <= 2) return this.$t("time.tomorrow");

      return then.toLocaleDateString();
    },
  },
};
</script>
