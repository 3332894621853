<template>
  <div class="flex flex-col">
    <div class="p-8 text-center text-gray-800">
      <div class="flex justify-center flex-col">
        <div class="text-lg mb-5">{{ $t("guestlogin.heading") }}</div>
        <p class="mb-5 text-sm">
          {{ $t("guestlogin.message") }}
        </p>
        <strong>{{ $t("profile.username") }}</strong>
        <input
          autocomplete="off"
          type="text"
          name="username"
          v-model="displayName"
          class="rounded-lg border-gray-500"
          minlength="5"
        />
      </div>
      <div v-if="showErrorMessage" class="text-sm text-red-500">
        The username has a minimum length of 4 chars
      </div>
      <button
        @click="save"
        class="max-w-xs w-2/3 mx-auto flex flex-row text-center justify-center justify-items-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
      >
        {{ $t("guestlogin.proceed") }}
      </button>
      <p>
        {{ $t("app.or") }}
        <router-link to="/login" class="text-indigo-500"
          >{{ $t("guestlogin.create") }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  import "firebase/analytics";
  export default {
    name: "Login",
    data() {
      return {
        user: {},
        displayName: "",
        showErrorMessage: false,
      };
    },
    props: {},
    mounted() {
      firebase.analytics().logEvent("login_guest");
      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          this.user = firebase.auth().currentUser;
        });
    },
    methods: {
      save() {
        if (this.user && this.displayName && this.displayName.length >= 4) {
          this.user.updateProfile({ displayName: this.displayName });
          this.$store.dispatch("setUser", this.user);
          var dbUser = false;
          if (!this.dbUser) {
            dbUser = {
              displayName: this.user.displayName,
              isAnonymous: this.user.isAnonymous,
              calendars: [],
            };
          } else {
            dbUser = this.dbUser;
            dbUser.displayName = this.user.displayName;
            dbUser.isAnonymous = this.user.isAnonymous;
          }

          this.$store.dispatch("setDbUser", dbUser);
          this.$store.dispatch("uploadDbUser");

          var calendarUsers = this.calendar.users;
          calendarUsers[this.user.uid].displayName = this.user.displayName;

          this.$store.dispatch("updateCalendarUsers", calendarUsers);
          this.$store.dispatch("uploadCalendarUsers");

          this.$router.push({
            name: "Home",
          });
        } else {
          this.showErrorMessage = true;
        }
      },
    },
    computed: {
      dbUser() {
        return this.$store.state.dbUser;
      },
      calendar() {
        return this.$store.state.activeCalendar;
      },
    },
  };
</script>
