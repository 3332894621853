import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vue2TouchEvents from "vue2-touch-events";
import VueClipboard from "vue-clipboard2";
import VueMeta from "vue-meta";
import i18n from "@/plugins/i18n";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import "./assets/styles/index.css";
import "./assets/styles/tailwind.css";
import "./assets/tailwind.css";

Vue.config.productionTip = false;

Vue.use(Vue2TouchEvents);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(VueClipboard);

import VueAwesomeSwiper from "vue-awesome-swiper";

// import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Sentry.init({
  Vue,
  dsn:
    "https://68f4a63c3bf2485dbd094ac12f373c46@o552624.ingest.sentry.io/5678697",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
