<template>
  <div v-if="user" class="text-gray-800">
    <ProfileImage :name="user.displayName" class="mt-10" />
    <div class="flex flex-col p-5 text-center">
      <div class="flex justify-center flex-col">
        <strong>{{ $t("profile.username") }}</strong>
        <input
          autocomplete="off"
          type="text"
          name="username"
          v-model="user.displayName"
          class="rounded-lg border-gray-500"
          minlength="5"
        />
      </div>
      <div class="flex justify-center flex-col mt-3">
        <strong>{{ $t("profile.language") }}</strong>
        <LanguageSelection />
      </div>
      <div class="flex justify-center flex-col mt-3">
        <strong>{{ $t("profile.calendarSettings") }}</strong>
        <label class="inline-flex items-center mt-2">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="dbUser.autoDecline"
          />
          <span class="ml-2">{{ $t("profile.autodecline") }}</span>
        </label>
      </div>
      <div class="flex justify-center flex-col mt-3">
        <strong>{{ $t("profile.notifications") }}</strong>
        <label class="inline-flex items-center mt-2">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="dbUser.pushChanges"
          />
          <span class="ml-2">{{ $t("profile.pushChanges") }}</span>
        </label>
        <label class="inline-flex items-center mt-2">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="dbUser.pushNewUsers"
          />
          <span class="ml-2">{{ $t("profile.pushNewUsers") }}</span>
        </label>
        <label class="inline-flex items-center mt-2">
          <input
            type="checkbox"
            class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            v-model="dbUser.pushAgreeOnDate"
          />
          <span class="ml-2">{{ $t("profile.pushAgreeOnDate") }}</span>
        </label>
      </div>
      <div class="flex justify-center mt-5 flex-col">
        <div v-if="!user.isAnonymous">
          <p><strong>Email</strong></p>
          <p>{{ vuexUser.email }}</p>
        </div>
      </div>

      <button
        @click="save"
        class="max-w-xs w-2/3 mx-auto flex flex-row text-center justify-center justify-items-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
      >
        {{ $t("app.save") }}
      </button>
      <div v-if="user.isAnonymous" class="mt-10">
        <p>
          {{ $t("profile.anonymous") }}
        </p>
        <div
          @click="createAccount"
          class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
        >
          <p class="font-semibold">{{ $t("app.createaccount") }}</p>
          <p class="text-sm">{{ $t("app.itsfree") }}</p>
        </div>
      </div>
      <div class="flex flex-col justify-center mt-5" v-if="!user.isAnonymous">
        <strong class="my-2 text-red-500">{{ $t("app.dangerzone") }}</strong>
        <p>
          {{ $t("profile.deletemessage") }}
        </p>
        <input
          autocomplete="off"
          type="text"
          name="confirmDelete"
          @keyup="$data.confirmDelete = $event.target.value"
          class="rounded-lg border-gray-500"
          minlength="5"
        />
        <button
          @click="deleteUser"
          class="max-w-xs w-2/3 mx-auto disabled:opacity-50 px-4 py-3 mt-5 m-2 text-sm text-red-100 transition-colors duration-150 bg-red-500 rounded-full focus:shadow-outline hover:bg-red-800"
          :disabled="!(confirmDelete == 'DELETE')"
        >
          {{ $t("app.delete") }}
        </button>
        <router-link
          to="/"
          class="max-w-xs w-2/3 mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-500 transition-colors duration-150 bg-indigo-100 rounded-full focus:shadow-outline hover:bg-indigo-200 shadow-md border-2 border-indigo-300"
          >{{ $t("profile.backtohome") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  import ProfileImage from "@/components/ProfileImage.vue";
  import LanguageSelection from "@/components/LanguageSelection.vue";
  export default {
    name: "Login",
    components: { ProfileImage, LanguageSelection },
    data() {
      return {
        user: {},
        dbUser: {},
        confirmDelete: "",
      };
    },
    mounted() {
      this.user = this.vuexUser;
      this.dbUser = this.vuexDbUser;
      if (!("autoDecline" in this.dbUser)) {
        this.dbUser.autoDecline = true;
      }
    },
    methods: {
      save() {
        this.$store.dispatch("setUser", this.user);
        firebase
          .auth()
          .currentUser.updateProfile({ displayName: this.user.displayName });
        this.$store.dispatch("setDbUser", this.dbUser);
        this.$store.dispatch("uploadDbUser");
        this.$router.push({
          name: "Home",
        });
      },
      async deleteUser() {
        if (this.confirmDelete == "DELETE") {
          await firebase.auth().currentUser.delete();
          this.$store.dispatch("setUser", false);
          this.$store.dispatch("setDbUser", false);
          this.$store.dispatch("setUserMenu", false);
          firebase.auth().signOut();
          this.$router.push({
            name: "Home",
          });
        }
      },
      createAccount() {
        localStorage.invitation = this.$store.getters.currentCalendarId;
        localStorage.replaceAnonymousUser = this.user.uid;
        this.$router.push({
          name: "Login",
        });
      },
    },
    computed: {
      vuexUser() {
        return this.$store.state.user;
      },
      vuexDbUser() {
        return this.$store.state.dbUser;
      },
      activeCalendar() {
        return this.$store.state.activeCalendar;
      },
    },
  };
</script>
