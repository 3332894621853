<template>
  <div class="text-justify p-5 overflow-hidden">
    <h2 class="my-2 text-lg font-bold">Privacy Policy</h2>
    <p class="my-2">
      Personal data (usually referred to just as "data" below) will only be
      processed by us to the extent necessary and for the purpose of providing a
      functional and user-friendly website, including its contents, and the
      services offered there.
    </p>
    <p class="my-2">
      Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data
      Protection Regulation (hereinafter referred to as the "GDPR"),
      "processing" refers to any operation or set of operations such as
      collection, recording, organization, structuring, storage, adaptation,
      alteration, retrieval, consultation, use, disclosure by transmission,
      dissemination, or otherwise making available, alignment, or combination,
      restriction, erasure, or destruction performed on personal data, whether
      by automated means or not.
    </p>
    <p class="my-2">
      The following privacy policy is intended to inform you in particular about
      the type, scope, purpose, duration, and legal basis for the processing of
      such data either under our own control or in conjunction with others. We
      also inform you below about the third-party components we use to optimize
      our website and improve the user experience which may result in said third
      parties also processing data they collect and control.
    </p>
    <p class="my-2">Our privacy policy is structured as follows:</p>
    <p class="my-2">
      I. Information about us as controllers of your data<br />II. The rights of
      users and data subjects<br />III. Information about the data processing
    </p>
    <h3>I. Information about us as controllers of your data</h3>
    <p class="my-2">
      The party responsible for this website (the "controller") for purposes of
      data protection law is:
    </p>
    <p class="my-2">
      <span>Lets JOIN - Valentin Sauer</span><br /><span
        >Eisenkramergasse 1</span
      ><br /><span>82362 Weilheim - Germany</span><br /><span>Germany</span>
    </p>
    <p class="my-2">
      <span>Telephone: +49 151 72729617</span><br /><span
        >Email: info@sauer-medientechnik.de</span
      >
    </p>
    <h3>II. The rights of users and data subjects</h3>
    <p class="my-2">
      With regard to the data processing to be described in more detail below,
      users and data subjects have the right
    </p>
    <ul type="disc">
      <li>
        to confirmation of whether data concerning them is being processed,
        information about the data being processed, further information about
        the nature of the data processing, and copies of the data (cf. also Art.
        15 GDPR);
      </li>
      <li>
        to correct or complete incorrect or incomplete data (cf. also Art. 16
        GDPR);
      </li>
      <li>
        to the immediate deletion of data concerning them (cf. also Art. 17
        DSGVO), or, alternatively, if further processing is necessary as
        stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per Art.
        18 GDPR;
      </li>
      <li>
        to receive copies of the data concerning them and/or provided by them
        and to have the same transmitted to other providers/controllers (cf.
        also Art. 20 GDPR);
      </li>
      <li>
        to file complaints with the supervisory authority if they believe that
        data concerning them is being processed by the controller in breach of
        data protection provisions (see also Art. 77 GDPR).
      </li>
    </ul>
    <p class="my-2">
      In addition, the controller is obliged to inform all recipients to whom it
      discloses data of any such corrections, deletions, or restrictions placed
      on processing the same per Art. 16, 17 Para. 1, 18 GDPR. However, this
      obligation does not apply if such notification is impossible or involves a
      disproportionate effort. Nevertheless, users have a right to information
      about these recipients.
    </p>
    <p class="my-2">
      <strong
        >Likewise, under Art. 21 GDPR, users and data subjects have the right to
        object to the controller's future processing of their data pursuant to
        Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data
        processing for the purpose of direct advertising is permissible.</strong
      >
    </p>
    <h3>III. Information about the data processing</h3>
    <p class="my-2">
      Your data processed when using our website will be deleted or blocked as
      soon as the purpose for its storage ceases to apply, provided the deletion
      of the same is not in breach of any statutory storage obligations or
      unless otherwise stipulated below.
    </p>
    <h4>Server data</h4>
    <p class="my-2">
      For technical reasons, the following data sent by your internet browser to
      us or to our server provider will be collected, especially to ensure a
      secure and stable website: These server log files record the type and
      version of your browser, operating system, the website from which you came
      (referrer URL), the webpages on our site visited, the date and time of
      your visit, as well as the IP address from which you visited our site.
    </p>
    <p class="my-2">
      The data thus collected will be temporarily stored, but not in association
      with any other of your data.
    </p>
    <p class="my-2">
      The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate
      interest lies in the improvement, stability, functionality, and security
      of our website.
    </p>
    <p class="my-2">
      The data will be deleted within no more than seven days, unless continued
      storage is required for evidentiary purposes. In which case, all or part
      of the data will be excluded from deletion until the investigation of the
      relevant incident is finally resolved.
    </p>
    <h4>Cookies</h4>
    <h5>a) Session cookies</h5>
    <p class="my-2">
      We use cookies on our website. Cookies are small text files or other
      storage technologies stored on your computer by your browser. These
      cookies process certain specific information about you, such as your
      browser, location data, or IP address. &nbsp;
    </p>
    <p class="my-2">
      This processing makes our website more user-friendly, efficient, and
      secure, allowing us, for example, to display our website in different
      languages or to offer a shopping cart function.
    </p>
    <p class="my-2">
      The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR,
      insofar as these cookies are used to collect data to initiate or process
      contractual relationships.
    </p>
    <p class="my-2">
      If the processing does not serve to initiate or process a contract, our
      legitimate interest lies in improving the functionality of our website.
      The legal basis is then Art. 6 Para. 1 lit. f) GDPR.
    </p>
    <p class="my-2">
      When you close your browser, these session cookies are deleted.
    </p>
    <h5>b) Third-party cookies</h5>
    <p class="my-2">
      If necessary, our website may also use cookies from companies with whom we
      cooperate for the purpose of advertising, analyzing, or improving the
      features of our website.
    </p>
    <p class="my-2">
      Please refer to the following information for details, in particular for
      the legal basis and purpose of such third-party collection and processing
      of data collected through cookies.
    </p>
    <h5>c) Disabling cookies</h5>
    <p class="my-2">
      You can refuse the use of cookies by changing the settings on your
      browser. Likewise, you can use the browser to delete cookies that have
      already been stored. However, the steps and measures required vary,
      depending on the browser you use. If you have any questions, please use
      the help function or consult the documentation for your browser or contact
      its maker for support. Browser settings cannot prevent so-called flash
      cookies from being set. Instead, you will need to change the setting of
      your Flash player. The steps and measures required for this also depend on
      the Flash player you are using. If you have any questions, please use the
      help function or consult the documentation for your Flash player or
      contact its maker for support.
    </p>
    <p class="my-2">
      If you prevent or restrict the installation of cookies, not all of the
      functions on our site may be fully usable.
    </p>
    <h4>Customer account/registration</h4>
    <p class="my-2">
      If you create a customer account with us via our website, we will use the
      data you entered during registration (e.g. your name, your address, or
      your email address) exclusively for services leading up to your potential
      placement of an order or entering some other contractual relationship with
      us, to fulfill such orders or contracts, and to provide customer care
      (e.g. to provide you with an overview of your previous orders or to be
      able to offer you a wishlist function). We also store your IP address and
      the date and time of your registration. This data will not be transferred
      to third parties.
    </p>
    <p class="my-2">
      During the registration process, your consent will be obtained for this
      processing of your data, with reference made to this privacy policy. The
      data collected by us will be used exclusively to provide your customer
      account.&nbsp;
    </p>
    <p class="my-2">
      If you give your consent to this processing, Art. 6 Para. 1 lit. a) GDPR
      is the legal basis for this processing.
    </p>
    <p class="my-2">
      If the opening of the customer account is also intended to lead to the
      initiation of a contractual relationship with us or to fulfill an existing
      contract with us, the legal basis for this processing is also Art. 6 Para.
      1 lit. b) GDPR.
    </p>
    <p class="my-2">
      You may revoke your prior consent to the processing of your personal data
      at any time under Art. 7 Para. 3 GDPR with future effect. All you have to
      do is inform us that you are revoking your consent.
    </p>
    <p class="my-2">
      The data previously collected will then be deleted as soon as processing
      is no longer necessary. However, we must observe any retention periods
      required under tax and commercial law.
    </p>
    <h4>Google Analytics</h4>
    <p class="my-2">
      We use Google Analytics on our website. This is a web analytics service
      provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
      Irland (hereinafter: Google).
    </p>
    <p class="my-2">
      The Google Analytics service is used to analyze how our website is used.
      The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest
      lies in the analysis, optimization, and economic operation of our site.
    </p>
    <p class="my-2">
      Usage and user-related information, such as IP address, place, time, or
      frequency of your visits to our website will be transmitted to a Google
      server in the United States and stored there. However, we use Google
      Analytics with the so-called anonymization function, whereby Google
      truncates the IP address within the EU or the EEA before it is transmitted
      to the US.
    </p>
    <p class="my-2">
      The data collected in this way is in turn used by Google to provide us
      with an evaluation of visits to our website and what visitors do once
      there. This data can also be used to provide other services related to the
      use of our website and of the internet in general.
    </p>
    <p class="my-2">
      Google states that it will not connect your IP address to other data. In
      addition, Google provides further information with regard to its data
      protection practices at
    </p>
    <p class="my-2">
      <a
        href="https://www.google.com/intl/de/policies/privacy/partners"
        target="_blank"
        rel="noopener"
        >https://www.google.com/intl/de/policies/privacy/partners,</a
      >
    </p>
    <p class="my-2">
      including options you can exercise to prevent such use of your data.
    </p>
    <p class="my-2">In addition, Google offers an opt-out add-on at</p>
    <p class="my-2">
      <a
        href="https://tools.google.com/dlpage/gaoptout?hl=de"
        target="_blank"
        rel="noopener"
        >https://tools.google.com/dlpage/gaoptout?hl=en</a
      >
    </p>
    <p class="my-2">
      in addition with further information. This add-on can be installed on the
      most popular browsers and offers you further control over the data that
      Google collects when you visit our website. The add-on informs Google
      Analytics' JavaScript (ga.js) that no information about the website visit
      should be transmitted to Google Analytics. However, this does not prevent
      information from being transmitted to us or to other web analytics
      services we may use as detailed herein.
    </p>
    <p class="my-2">
      <a
        href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
        target="_blank"
        rel="noopener"
        >Model Data Protection Statement</a
      >
      for
      <a href="https://www.ratgeberrecht.eu/" target="_blank" rel="noopener"
        >Anwaltskanzlei Weiß &amp; Partner</a
      >
    </p>
  </div>
</template>

<script>
  export default {
    name: "LegalNotice",
  };
</script>
