<template>
  <div class="flex justify-center">
    <div class="inline-block">
      <div class="w-10 h-10 min-w-10 max-w-full">
        <div
          class="w-full h-full rounded-full overflow-hidden text-center bg-gray-200 table ring-4"
          :class="classObject"
        >
          <span class="table-cell font-bold align-middle text-sm">
            +{{ name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfileImagePlus",
  props: {
    name: Number,
    status: {
      type: String,
      default: null,
    },
    overallStatus: {
      type: String,
      default: null,
    },
  },
  computed: {
    classObject() {
      return {
        "ring-red-500":
          this.status == "declined" && this.overallStatus != "success",
        "ring-green-500":
          this.status == "accepted" && this.overallStatus != "success",
        "ring-yellow-300": this.overallStatus == "success",
        "text-red-500":
          this.status == "declined" && this.overallStatus != "success",
        "text-green-500":
          this.status == "accepted" && this.overallStatus != "success",
        "text-yellow-300": this.overallStatus == "success",
      };
    },
  },
};
</script>
