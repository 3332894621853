import firebase from "firebase/app";
import "firebase/database";
import "firebase/messaging";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCok2nRht6GsL82fALek7nq_ppGWtmggZc",
  authDomain: "calendar-app-304411.firebaseapp.com",
  databaseURL:
    "https://calendar-app-304411-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "calendar-app-304411",
  storageBucket: "calendar-app-304411.appspot.com",
  messagingSenderId: "379284183694",
  appId: "1:379284183694:web:a0f66d30cedbcd626a87df",
  measurementId: "G-RLBJSS4JWZ",
};

const db = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig).database()
  : firebase.app().database();

const analytics = firebase.app().analytics();
const messaging = firebase.messaging.isSupported()
  ? firebase.app().messaging()
  : null;

// Get a Firestore instance
export { db, analytics, messaging };
