import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import LoginAnonymously from "../views/LoginAnonymously.vue";
import Profile from "../views/Profile.vue";
import Calendar from "../views/Calendar.vue";
import Invite from "../views/Invite.vue";
import Support from "../views/Support.vue";
import NotFound from "../views/NotFound.vue";
import InvitationError from "../views/InvitationError.vue";
import LegalNotice from "../views/LegalNotice.vue";
import DataProtection from "../views/DataProtection.vue";
import store from "../store/index.js";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login-anonymously",
    name: "LoginAnonymously",
    component: LoginAnonymously,
  },
  {
    path: "/legalnotice",
    name: "LegalNotice",
    component: LegalNotice,
  },
  {
    path: "/dataprotection",
    name: "DataProtection",
    component: DataProtection,
  },
  {
    path: "/invitation-error",
    name: "InvitationError",
    component: InvitationError,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/invite/:calendarId",
    name: "Invite",
    component: Invite,
    props: { allowGuest: false },
  },
  {
    path: "/invite/g/:calendarId",
    name: "InviteGuest",
    component: Invite,
    props: { allowGuest: true },
  },
  {
    path: "/calendar/:calendarId",
    name: "Calendar",
    component: Calendar,
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      auth: true,
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("setNavMenu", false);
  store.dispatch("setUserMenu", false);
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.state.user) {
      next();
    } else {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    }
  } else {
    next();
  }
});

export default router;
