<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <nav class="fixed w-full bg-gray-800 z-10">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
            @click="toggleNavMenu"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!-- Icon when menu is closed. -->
            <!--
            Heroicon name: outline/menu

            Menu open: "hidden", Menu closed: "block"
          -->
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!-- Icon when menu is open. -->
            <!--
            Heroicon name: outline/x

            Menu open: "block", Menu closed: "hidden"
          -->
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block lg:hidden h-8 w-auto"
              :src="'/img/logo-small.png'"
              alt="Workflow"
              v-if="$route.name != 'Calendar'"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              :src="'/img/logo-small.png'"
              alt="Workflow"
              v-if="$route.name != 'Calendar'"
            />
            <span
              v-if="$route.name === 'Calendar' && calendar && calendar.name"
              class="font-semibold text-indigo-100 text-lg"
              >{{ calendar.name }}</span
            >
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <router-link
                to="/"
                class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                >Home</router-link
              >
              <router-link
                :to="{
                  name: 'Calendar',
                  params: { calendarId: calendar.key },
                }"
                v-if="isActiveUser && calendar && calendar.name && calendar.key"
                class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                >{{ calendar.name }}</router-link
              >
              <router-link
                to="/support"
                class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                >Support</router-link
              >
              <router-link
                to="/legalnotice"
                class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                >{{ $t("app.legalnotice") }}</router-link
              >
              <router-link
                to="/dataprotection"
                class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                >{{ $t("app.dataprotection") }}</router-link
              >
            </div>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <button
            class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            v-if="isActiveUser"
          >
            <span class="sr-only">View notifications</span>
            <!-- Heroicon name: outline/bell -->
            <svg
              class="h-6 w-6"
              :class="alertClass"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button>
          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div>
              <button
                v-if="isActiveUser"
                class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                id="user-menu"
                aria-haspopup="true"
                @click="toggleUserMenu"
              >
                <span class="sr-only">Open user menu</span>
                <ProfileImage :name="user.displayName" />
              </button>
            </div>
            <!--
            Profile dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
            <div
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
              v-if="usermenu"
            >
              <router-link
                to="/profile"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                ><span v-if="!user.isAnonymous">{{ $t("app.your") }}</span
                ><span v-else>{{ $t("app.anonymous") }}</span>
                {{ $t("app.profile") }}</router-link
              >
              <a
                href="#"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                @click="signoutButtonPressed"
                >{{ $t("app.signout") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
    <div v-if="navmenu">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link
          to="/"
          class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
          >Home</router-link
        >
        <router-link
          :to="{
            name: 'Calendar',
            params: { calendarId: calendar.key },
          }"
          v-if="isActiveUser && calendar && calendar.name && calendar.key"
          class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
          >{{ calendar.name }}</router-link
        >
        <router-link
          to="/support"
          class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
          >Support</router-link
        >
        <div class="flex flex-row">
          <router-link
            to="/legalnotice"
            class="text-gray-300 block px-3 py-2 rounded-md text-sm w-1/2 mr-2"
            >{{ $t("app.legalnotice") }}</router-link
          >
          <router-link
            to="/dataprotection"
            class=" text-gray-300 block px-3 py-2 rounded-md text-sm w-1/2 ml-2"
            >{{ $t("app.dataprotection") }}</router-link
          >
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  import ProfileImage from "@/components/ProfileImage.vue";
  export default {
    name: "Navbar",
    components: { ProfileImage },
    methods: {
      signoutButtonPressed(e) {
        this.$store.dispatch("setUser", false);
        this.$store.dispatch("setDbUser", false);
        this.$store.dispatch("setUserMenu", false);
        e.stopPropagation();
        firebase.auth().signOut();
        if (this.$route.name != "Home") {
          this.$router.push({
            name: "Home",
          });
        }
      },
      toggleUserMenu() {
        this.$store.dispatch("setNavMenu", false);
        this.$store.dispatch("setUserMenu", !this.usermenu);
      },
      toggleNavMenu() {
        this.$store.dispatch("setUserMenu", false);
        this.$store.dispatch("setNavMenu", !this.navmenu);
      },
    },
    computed: {
      isActiveUser() {
        var isActiveUser =
          (this.user &&
            this.user.email &&
            this.user.providerData &&
            this.user.providerData[0] &&
            this.user.providerData[0].providerId &&
            ((this.user.email &&
              this.user.providerData[0].providerId != "password") ||
              (this.user.providerData[0].providerId == "password" &&
                this.user.emailVerified))) ||
          this.user.isAnonymous;
        return isActiveUser;
      },
      user() {
        return this.$store.state.user;
      },
      numberOfAlerts() {
        if (this.dbUser && this.dbUser.messages) {
          return this.dbUser.messages.length;
        }
        return 0;
      },
      alertClass() {
        return { "text-red-500": this.numberOfAlerts > 0 };
      },
      dbUser() {
        return this.$store.state.dbUser;
      },
      navmenu() {
        return this.$store.state.navmenu;
      },
      usermenu() {
        return this.$store.state.usermenu;
      },
      calendar() {
        return this.$store.state.activeCalendar;
      },
    },
  };
</script>
