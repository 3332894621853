<template>
  <div class="text-center text-gray-800 subpixel-antialiased antialiased">
    <HomeLoggedIn v-if="user" />
    <HomeHomepage v-else />
  </div>
</template>

<script>
  import HomeHomepage from "@/components/HomeHomepage.vue";
  import HomeLoggedIn from "@/components/HomeLoggedIn.vue";
  export default {
    name: "Home",
    components: { HomeHomepage, HomeLoggedIn },
    data() {
      return {};
    },
    metaInfo() {
      return {
        title: "Let's Join - Find meeting-dates faster",
        meta: [
          {
            content:
              "Let's Join makes it easy to agree on meeting-dates in private groups",
          },
        ],
        htmlAttrs: {
          lang: "en",
          amp: true,
        },
      };
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
    },
    methods: {},
  };
</script>
