<template>
  <div class="flex justify-center">
    <div class="inline-block">
      <div class="w-10 h-10 min-w-10 max-w-full">
        <div
          class="w-full h-full rounded-full overflow-hidden text-center bg-gray-200 table"
          :style="{ backgroundColor: stringToHslColor }"
          :class="classObject"
        >
          <span class="table-cell text-white font-bold align-middle text-sm">
            {{ initials }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "ProfileImage",
    props: {
      name: String,
      status: {
        type: String,
        default: null,
      },
      overallStatus: {
        type: String,
        default: null,
      },
    },
    computed: {
      classObject() {
        return {
          "ring-4": this.status,
          "ring-red-500":
            this.status == "declined" && this.overallStatus != "success",
          "ring-green-500":
            this.status == "accepted" && this.overallStatus != "success",
          "ring-yellow-300": this.overallStatus == "success",
        };
      },
      initials() {
        if (this.name) {
          let firstLetter = this.name.charAt(0);
          let lastLetter = this.name
            .split(" ")
            .pop()
            .charAt(0);
          return (firstLetter + lastLetter).toUpperCase();
        }
        return "...";
      },
      stringToHslColor() {
        let str = this.initials;
        const s = 40;
        const l = 50;
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        const h = hash % 360;
        return `hsl(${h}, ${s}%, ${l}%)`;
      },
    },
  };
</script>
