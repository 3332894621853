<template>
  <div>
    <div v-if="isActiveUser">
      <div
        class="pt-10 px-5 pb-10 w-full rounded-b-3xl text-indigo-100 bg-gradient-to-b from-gray-800 to-indigo-500 shadow"
      >
        <div v-if="!calendars.length">
          <p class="text-lg font-semibold">
            {{ $t("home.welcometo") }} Let's Join!
          </p>
          <div v-if="!this.user.isAnonymous">
            <p class="my-5">
              {{ $t("home.createfirstcalendar") }}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              class="fill-current text-indigo-100 mx-auto animate-ping"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
              />
            </svg>
          </div>
          <div v-if="this.user.isAnonymous && showNoAnonymousAllowedMessage">
            <p class="mt-10">
              {{ $t("home.anonymousnotallowed") }}
            </p>
          </div>
        </div>
        <div v-if="calendars.length" class="flex flex-col">
          <p class="mb-5">{{ $t("home.yourcalendars") }}</p>
          <router-link
            v-for="(calendar, index) in calendars"
            v-show="calendar.key"
            :key="index"
            :to="{ name: 'Calendar', params: { calendarId: calendar.key } }"
            class="mx-3 my-3 pt-3 pt-1 text-left bg-gray-100 text-indigo-900 rounded-lg shadow-lg"
            ><p class="font-semibold text-lg mx-5">
              {{ calendar.name }}
            </p>
            <div
              class="text-sm flex flex-row mx-5 mb-2 mt-1"
              v-if="nextCalendarOptions(calendar)"
            >
              <span
                class="text-left w-1/2"
                v-if="nextCalendarOptions(calendar).possible"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                  class="float-left fill-current text-green-500"
                >
                  <g><rect fill="none" height="24" width="24" x="0" /></g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M22,9.24l-7.19-0.62L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27L18.18,21l-1.63-7.03L22,9.24z M12,15.4V6.1 l1.71,4.04l4.38,0.38l-3.32,2.88l1,4.28L12,15.4z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <TimeText
                  :time="nextCalendarOptions(calendar).possible"
                  :future="true"
                /> </span
              ><span
                class="text-right w-1/2"
                v-if="nextCalendarOptions(calendar).agreed"
                ><TimeText
                  :time="nextCalendarOptions(calendar).agreed"
                  :future="true"/>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                  class="float-right fill-current text-yellow-500"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                  /></svg
              ></span>
            </div>
            <div
              class="text-xs flex flex-row bg-gray-200 px-5 rounded-b-lg py-1 mt-1"
            >
              <span class="text-left w-1/3"
                >{{ getUserNumber(calendar) }} {{ $t("app.users") }}</span
              ><span class="text-right w-2/3"
                >{{ $t("app.lastupdate") }}:
                <TimeText :time="calendar.lastUpdate"
              /></span>
            </div>
          </router-link>
        </div>
      </div>
      <div
        v-if="!user.isAnonymous"
        class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md  cursor-pointer"
        @click="createNewCalendar"
      >
        <p class="font-semibold">{{ $t("home.createnewcalendar") }}</p>
        <p class="text-sm">{{ $t("app.readyIn1Click") }}</p>
      </div>
      <div v-else class="mt-10 p-5">
        <p>
          {{ $t("home.guestcannot") }}
        </p>
        <div
          @click="createAccount"
          class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 my-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md  cursor-pointer"
        >
          <p class="font-semibold">{{ $t("app.createaccount") }}</p>
          <p class="text-sm">{{ $t("app.itsfree") }}</p>
        </div>
      </div>
    </div>
    <div v-if="!isActiveUser">
      <p class="text-center my-10 px-5">
        {{ $t("home.verifyemail") }}
      </p>
      <p
        @click="resendEmail"
        v-if="!sent"
        class="text-indigo-700 cursor-pointer"
      >
        {{ $t("home.resendemail") }}
      </p>
      <p v-else class="my-3 font-bold">
        {{ $t("home.emailresent") }}
      </p>
      <router-link
        to="/login"
        class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 my-3 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md  cursor-pointer"
      >
        <p class="font-semibold">
          {{ $t("app.login") }}
        </p>
      </router-link>
      <p class="mt-5">
        {{ $t("home.loginotheruser") }}
        <a
          class="text-indigo-700 cursor-pointer"
          href="#"
          @click="signoutButtonPressed"
          >{{ $t("app.here") }}</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import TimeText from "@/components/TimeText.vue";
import { db } from "../db";
export default {
  name: "HomeLoggedIn",
  components: { TimeText },
  data() {
    return {
      sent: false,
      showNoAnonymousAllowedMessage: false,
    };
  },
  metaInfo() {
    return {
      title: "Let's Join - Calendar List",
      meta: [
        {
          content:
            "Let's Join makes it easy to agree on meeting-dates in private groups",
        },
      ],
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  async mounted() {
    await this.loadCalendars();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    dbUser() {
      return this.$store.state.dbUser;
    },
    dbUserCalendars() {
      if (this.dbUser) return this.dbUser.calendars;
      return 0;
    },
    calendars() {
      return this.$store.state.calendars;
    },
    activeCalendar() {
      return this.$store.state.activeCalendar;
    },
    isActiveUser() {
      var isActiveUser =
        (this.user &&
          this.user.email &&
          this.user.providerData &&
          this.user.providerData[0] &&
          this.user.providerData[0].providerId &&
          ((this.user.email &&
            this.user.providerData[0].providerId != "password") ||
            (this.user.providerData[0].providerId == "password" &&
              this.user.emailVerified))) ||
        this.user.isAnonymous;
      return isActiveUser;
    },
  },
  methods: {
    async loadCalendars() {
      if (this.user && this.dbUser) {
        if (this.dbUser.calendars && this.dbUser.calendars.length) {
          await this.loadCalendarsNewWay();
        } else {
          await this.loadCalendarsLegacyWay();
        }
      }
    },
    async loadCalendarsLegacyWay() {
      var myCalendarIds = [];
      var calendars = [];
      const snapshot = await db.ref("calendars").once("value");
      if (snapshot.exists()) {
        const remoteCalendars = snapshot.val();
        if (remoteCalendars) {
          for (var calendarId in remoteCalendars) {
            var calendar = remoteCalendars[calendarId];
            if (calendar && calendar.users && calendar.users[this.user.uid]) {
              calendar.key = calendarId;
              myCalendarIds.push(calendarId);
              calendars.push(calendar);
            }
          }
          this.$store.dispatch("setCalendars", calendars);
          var updatedDbUser = this.dbUser;
          if (updatedDbUser.calendars != myCalendarIds) {
            updatedDbUser.calendars = myCalendarIds;
            this.$store.dispatch("setDbUser", updatedDbUser);
            await this.$store.dispatch("uploadDbUser");
          }
        }
      }
    },
    async loadCalendarsNewWay() {
      var calendars = [];
      for (var i = 0; i < this.dbUser.calendars.length; i++) {
        var calendarId = this.dbUser.calendars[i];
        const snapshot = await db.ref("calendars/" + calendarId).once("value");
        if (snapshot.exists()) {
          const calendar = snapshot.val();
          calendar.key = calendarId;
          calendars.push(calendar);
        }
        this.$store.dispatch("setCalendars", calendars);
      }
    },
    resendEmail() {
      this.user.sendEmailVerification();
      this.sent = true;
    },
    signoutButtonPressed(e) {
      this.$store.dispatch("setUser", false);
      e.stopPropagation();
      firebase.auth().signOut();
      this.$router.push({
        name: "Home",
      });
    },
    async createNewCalendar() {
      firebase.analytics().logEvent("create_calendar");
      var newCalendar = await this.$store.dispatch("addCalendar", {
        uid: this.user.uid,
        displayName: this.user.displayName,
      });
      const calendarId = newCalendar.path.pieces_[1];
      const calendar = await this.getCalendar(calendarId);
      this.$store.dispatch("mapCalendarAndUser", {
        calendar,
        dbUser: this.dbUser,
      });
      this.$router.push({
        name: "Calendar",
        params: { calendarId },
      });
    },
    getUserNumber(calendar) {
      var count = 0;
      if (calendar && calendar.users) {
        for (var k in calendar.users) if (k) count++;
      }
      return count;
    },
    createAccount() {
      localStorage.invitation = this.$store.getters.currentCalendarId;
      localStorage.replaceAnonymousUser = this.user.uid;
      this.$router.push({
        name: "Login",
      });
    },
    async getCalendar(calendarId) {
      const payload = await db.ref("calendars/" + calendarId).once("value");
      if (payload.exists()) {
        var calendar = payload.val();
        calendar.key = calendarId;
        return calendar;
      }
      return false;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    nextCalendarOptions(calendar) {
      var options = {
        possible: false,
        agreed: false,
      };
      if (calendar.days) {
        for (var dayId in calendar.days) {
          if (calendar.days[dayId].users) {
            var all = true;
            for (var userId in calendar.days[dayId].users) {
              if (calendar.days[dayId].users[userId].status == "declined") {
                all = false;
              }
            }
            if (all && !options.possible) options.possible = dayId;
            if (
              all &&
              calendar.days &&
              !options.agreed &&
              Object.keys(calendar.users).length ===
                Object.keys(calendar.days[dayId].users).length
            )
              options.agreed = dayId;
          }
        }
      }
      return options;
    },
  },
  watch: {
    user() {
      this.loadCalendars();
    },
    dbUser() {
      this.loadCalendars();
    },
    activeCalendar() {
      this.loadCalendars();
    },
  },
};
</script>
