<template>
  <div class="fixed top-0 bottom-0">
    <div
      class="fixed top-0 bottom-0 bg-gray-800 z-500 w-full p-5 shadow-inner opacity-80"
    ></div>
    <div class="fixed left-0 bottom-0 bg-white z-10 w-full p-5 shadow-inner">
      <div class="absolute right-3 top-3" @click="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          class="fill-current text-gray-780"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
      </div>
      <div class="flex flex-row">
        <img
          class="h-10 w-auto rounded-lg float-left"
          :src="'/img/icons/android-chrome-192x192.png'"
          alt="Workflow"
          v-if="$route.name != 'Calendar'"
        />
        <p class=" float-left font-semibold text-lg ml-5 mt-1">
          {{ $t("pwa.installHead") }}
        </p>
      </div>
      <p class="mt-3">
        {{ $t("pwa.message") }}
      </p>
      <p class="text-sm my-3">
        <span class="font-semibold">Android:</span>
        {{ $t("pwa.android") }}
      </p>
      <p class="text-sm my-3">
        <span class="font-semibold">iOS:</span> {{ $t("pwa.ios") }}
      </p>
      <div
        class="max-w-xs mx-auto flex flex-col text-center justify-center px-4 py-3 mt-5 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-500 rounded-full focus:shadow-outline hover:bg-indigo-800 shadow-md"
        @click="close"
      >
        {{ $t("pwa.proceed") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InstallPromt",
  mounted() {
    localStorage.lastInstallPrompt = new Date();
  },
  computed: {},
  methods: {
    close() {
      this.$emit("closeInstallPrompt");
    },
  },
};
</script>
