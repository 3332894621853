<template>
  <div>
    <button
      class="w-2/3 text-center justify-center px-4 py-3 my-3 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-full focus:shadow-outline hover:bg-indigo-800 text-white flex flex-row mx-auto shadow-lg"
      type="button"
      title="Share this calendar"
      @click="openDialog"
    >
      <svg class="fill-current text-indigo-100">
        <use href="#share-icon"></use>
      </svg>
      <span class="text-indigo-100">{{ $t("invite.share") }}</span>
    </button>

    <div class="flex justify-center">
      <div class="share-dialog" v-if="showDialog">
        <header>
          <h3 class="dialog-title">{{ $t("invite.sharethiscalendar") }}</h3>
          <button class="close-button" @click="showDialog = false">
            <svg><use href="#close"></use></svg>
          </button>
        </header>
        <div class="link">
          <div class="pen-url">{{ url }}</div>
          <button
            @click="copyToClipboard"
            class="px-4 py-3 my-3 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800 flex flex-row mx-auto"
          >
            {{ $t("invite.copylink") }}
          </button>
        </div>
      </div>

      <svg>
        <defs>
          <symbol
            id="share-icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-share"
          >
            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
            <polyline points="16 6 12 2 8 6"></polyline>
            <line x1="12" y1="2" x2="12" y2="15"></line>
          </symbol>

          <symbol
            id="close"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x-square"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="9" y1="9" x2="15" y2="15"></line>
            <line x1="15" y1="9" x2="9" y2="15"></line>
          </symbol>
        </defs>
      </svg>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/analytics";
export default {
  name: "WebShare",
  props: {
    url: String,
    title: String,
  },
  data() {
    return { showDialog: false };
  },
  methods: {
    openDialog() {
      firebase.analytics().logEvent("open_share_dialog");
      if (navigator.share) {
        navigator.share({
          title: "Let's Join - " + this.title,
          text: this.$t("share.text1") + this.title + this.$t("share.text2"),
          url: this.url,
        });
      } else {
        this.showDialog = true;
      }
    },
    copyToClipboard() {
      this.$copyText(this.url);
      this.showDialog = false;
    },
  },
};
</script>

<style scoped>
svg {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.share-button,
.copy-link {
  padding-left: 30px;
  padding-right: 30px;
}

.share-dialog {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-dialog {
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 10;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
}

.share-dialog.is-open {
  display: block;
  z-index: 2;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.close-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.close-button svg {
  margin-right: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
