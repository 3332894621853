<template>
  <div>
    <div
      class="my-3 float-left w-full"
      style="height: 110px"
      v-if="calendar.options.weekdays[getDayOfWeek(day.date)]"
    >
      <swiper
        ref="mySwiper{{day.date}}"
        :options="swiperOptions"
        class="h-full"
        @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
      >
        <swiper-slide class="h-full">
          <div
            class="w-full p-5 pt-10 text-green-100 bg-green-500 text-right h-full text-lg font-bold"
          >
            Yeah! 😊
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="absolute w-full h-full" v-touch:longtap="editDay"></div>
          <div class="bg-gray-100 flex flex-col h-full">
            <div
              class="bg-gray-100 px-3 border-t-4 flex flex-row pt-1"
              v-bind:class="overallStatusClass"
            >
              <div class="w-1/2 flex flex-col">
                <div class="text-4xl" v-bind:class="successStatusClass">
                  {{ getDayOfWeekLang(day.date) }}
                </div>
                <div class="text-sm text-gray-800">
                  <TimeText :time="day.date" :future="true" />
                  <span v-if="day.startTime || day.endTime"
                    >{{ day.startTime ? day.startTime : "??:??" }}-{{
                      day.endTime ? day.endTime : "??:??"
                    }}</span
                  >
                </div>
              </div>
              <div class="w-1/2 flex flex-col text-right">
                <span class="h-2 text-sm mb-2 text-gray-800 font-semibold">{{
                  day.dayTitle
                }}</span>
                <div class="flex flex-row-reverse mt-3 h-10">
                  <ProfileImagePlus
                    v-if="hiddenUsers && hiddenUsers.declined > 0"
                    :name="hiddenUsers.declined"
                    status="declined"
                    :overallStatus="getOverallDayStatus"
                    class="-ml-3"
                  />
                  <ProfileImagePlus
                    v-if="hiddenUsers && hiddenUsers.accepted > 0"
                    :name="hiddenUsers.accepted"
                    status="accepted"
                    :overallStatus="getOverallDayStatus"
                    class="-ml-3"
                  />
                  <div v-for="user in shownUsers" :key="user.uid">
                    <ProfileImage
                      :name="user.displayName"
                      :status="user.status"
                      :overallStatus="getOverallDayStatus"
                      class="-ml-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-sm px-3 pb-1 pt-1 text-gray-800 h-8 flex flex-row">
              <div
                class="text-left w-1/2 flex flex-col"
                style="margin-top: -8px"
              >
                <div>
                  <div
                    class="w-2 h-2 min-w-2 max-w-full float-left mr-1"
                    style="margin-top: 4px;"
                  >
                    <div
                      class="w-full h-full rounded-full overflow-hidden text-center bg-gray-200 table"
                      :class="myStatusClass"
                    ></div>
                  </div>
                  <span class="float-left text-gray-500 text-xs">
                    {{ myStatusMessage }}</span
                  >
                </div>
                <div
                  v-if="dayInterference"
                  class="text-gray-500 text-xs flex flex-row"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    viewBox="0 0 24 24"
                    width="16"
                    class="fill-current text-yellow-500"
                    style="margin-left: -4px"
                  >
                    <path
                      d="M11 21h-1l1-7H7.5c-.58 0-.57-.32-.38-.66.19-.34.05-.08.07-.12C8.48 10.94 10.42 7.54 13 3h1l-1 7h3.5c.49 0 .56.33.47.51l-.07.15C12.96 17.55 11 21 11 21z"
                    />
                  </svg>
                  <span
                    >{{ $t("app.conflict." + dayInterference.status) }}
                    {{
                      dayInterference.calendar.replace(
                        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                        ""
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="text-right w-1/2 text-gray-500 text-xs pt-1">
                {{ dayMessage }}
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="h-full">
          <div
            class="w-full p-5 pt-10 text-red-100 bg-red-500 text-left h-full text-lg font-bold"
          >
            🙈 Nooo!
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-else>
      <div class="mx-auto text-xs text-gray-200 text-center">
        {{ getDayOfWeek(day.date) }} hidden due to calendar settings
      </div>
    </div>
  </div>
</template>
<script>
  import ProfileImage from "@/components/ProfileImage.vue";
  import ProfileImagePlus from "@/components/ProfileImagePlus.vue";
  import TimeText from "@/components/TimeText.vue";
  import firebase from "firebase/app";
  import "firebase/analytics";
  export default {
    name: "DayCard",
    components: {
      ProfileImage,
      ProfileImagePlus,
      TimeText,
    },
    data() {
      return {
        swiperOptions: {
          initialSlide: 1,
          touchAngle: 25,
        },
      };
    },
    props: {
      day: Object,
    },
    methods: {
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      },
      getDayOfWeek(date) {
        const dayOfWeek = new Date(date).getDay();
        return isNaN(dayOfWeek)
          ? null
          : [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ][dayOfWeek];
      },
      getDayOfWeekLang(date) {
        const dayOfWeek = new Date(date).getDay();
        return isNaN(dayOfWeek)
          ? null
          : [
              this.$t("app.sunday"),
              this.$t("app.monday"),
              this.$t("app.tuesday"),
              this.$t("app.wednesday"),
              this.$t("app.thursday"),
              this.$t("app.friday"),
              this.$t("app.saturday"),
            ][dayOfWeek];
      },
      onSlideChangeTransitionEnd(swiper) {
        var changedUser = false;
        if (swiper.activeIndex === 0) {
          changedUser = {
            uid: this.user.uid,
            displayName: this.user.displayName,
            status: "accepted",
            lastUpdate: new Date().toISOString(),
          };
        } else if (swiper.activeIndex === 2) {
          changedUser = {
            uid: this.user.uid,
            displayName: this.user.displayName,
            status: "declined",
            lastUpdate: new Date().toISOString(),
          };
        }
        if (!this.day.users) {
          this.day.users = {};
        }
        this.day.users[this.user.uid] = changedUser;

        if (!("autoDecline" in this.dbUser) || this.dbUser.autoDecline) {
          for (var dayId in this.calendar.days) {
            if (
              this.calendar.days[dayId] &&
              new Date(this.calendar.days[dayId].date) <
                new Date(this.day.date) &&
              (!this.calendar.days[dayId].users ||
                !this.calendar.days[dayId].users[this.user.uid])
            ) {
              if (!this.calendar.days[dayId].users) {
                this.calendar.days[dayId].users = {};
              }
              this.calendar.days[dayId].users[this.user.uid] = {
                uid: this.user.uid,
                displayName: this.user.displayName,
                status: "declined",
                lastUpdate: new Date().toISOString(),
              };
            }
          }
        }

        this.calendar.days[this.day.date.split("T")[0]] = this.day;

        this.$emit("updateDays", this.calendar.days);
        firebase.analytics().logEvent("swipe");
        swiper.slideTo(1, 100, false);
      },
      editDay() {
        this.$emit("editDay", this.day);
      },
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      dbUser() {
        return this.$store.state.dbUser;
      },
      successStatusClass() {
        return {
          "text-gray-300": this.getOverallDayStatus != "success",
          "text-yellow-400": this.getOverallDayStatus === "success",
        };
      },
      myStatusClass() {
        return {
          "bg-red-500": this.getMyDayStatus == "declined",
          "bg-green-500": this.getMyDayStatus == "accepted",
        };
      },
      myStatusMessage() {
        if (this.getMyDayStatus == "declined")
          return this.$t("app.notavailable");
        if (this.getMyDayStatus == "accepted") return this.$t("app.available");
        return this.$t("app.notvotedyet");
      },
      overallStatusClass() {
        return {
          "border-red-500":
            this.getMyDayStatus == "declined" &&
            this.getOverallDayStatus != "success",
          "border-green-500":
            this.getMyDayStatus == "accepted" &&
            this.getOverallDayStatus != "success",
          "border-yellow-400": this.getOverallDayStatus == "success",
        };
      },
      dayUsers() {
        if (this.day.users) {
          return Object.keys(this.day.users).length;
        }
        return 0;
      },
      calendarUsers() {
        if (this.calendar.users) {
          return Object.keys(this.calendar.users).length;
        }
        return 0;
      },
      getOverallDayStatus() {
        if (this.dayUsers === 0) return "not votes yet";
        for (var prop in this.day.users) {
          if (this.day.users[prop].status === "declined") return "impossible";
        }
        if (this.calendarUsers == this.dayUsers) return "success";
        return "possible";
      },
      getMyDayStatus() {
        if (this.day && this.day.users && this.day.users[this.user.uid]) {
          return this.day.users[this.user.uid].status;
        }
        return false;
      },
      initials() {
        if (this.name) {
          let firstLetter = this.name.charAt(0);
          let lastLetter = this.name
            .split(" ")
            .pop()
            .charAt(0);
          return (firstLetter + lastLetter).toUpperCase();
        }
        return "...";
      },
      stringToHslColor() {
        let str = this.initials;
        const s = 40;
        const l = 50;
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        const h = hash % 360;
        return `hsl(${h}, ${s}%, ${l}%)`;
      },
      calendar() {
        return this.$store.state.activeCalendar;
      },
      calendars() {
        return this.$store.state.calendars;
      },
      dayMessage() {
        if (this.calendar.users && this.day.users) {
          const calendarUsers = Object.keys(this.calendar.users).length;
          const dayUsers = Object.keys(this.day.users).length;
          var availableUsers = 0;
          for (var prop in this.day.users) {
            if (this.day.users[prop].status === "accepted") availableUsers++;
          }
          if (calendarUsers === dayUsers) {
            if (availableUsers === calendarUsers)
              return this.$t("calendar.everyoneavailable");
          } else if (calendarUsers === dayUsers + 1) {
            for (prop in this.calendar.users) {
              if (
                !this.day.users[this.calendar.users[prop].uid] &&
                this.calendar.users[prop].displayName &&
                this.getOverallDayStatus == "possible"
              )
                return (
                  this.$t("calendar.only") +
                  (this.calendar.users[prop].uid === this.user.uid
                    ? this.$t("calendar.youaremissing")
                    : " " +
                      this.calendar.users[prop].displayName.split(" ")[0] +
                      " " +
                      this.$t("calendar.ismissing"))
                );
            }
          }
        }
        return " ";
      },
      shownUsers() {
        if (this.dayUsers === 6) {
          return this.day.users;
        }
        var i = 0;
        var users = {};
        for (var user in this.day.users) {
          if (i <= 4) {
            users[user] = this.day.users[user];
          }
          i++;
        }
        return users;
      },
      hiddenUsers() {
        var accepted = 0;
        var declined = 0;
        if (this.dayUsers <= 6) {
          return { accepted, declined };
        }
        var i = 0;
        for (var user in this.day.users) {
          if (i > 4) {
            if (this.day.users[user].status === "declined") {
              declined++;
            } else {
              accepted++;
            }
          }
          i++;
        }
        return { accepted, declined };
      },
      dayInterference() {
        var dayInterference = false;
        const calendarId = this.calendar.key
          ? this.calendar.key
          : this.calendar[".key"];
        this.calendars.forEach((calendar) => {
          if (
            calendar.key != calendarId &&
            calendar.days &&
            calendar.days[this.day.date] &&
            calendar.days[this.day.date].users &&
            calendar.days[this.day.date].users[this.user.uid] &&
            calendar.days[this.day.date].users[this.user.uid].status ==
              "accepted"
          ) {
            var all = true;

            for (var prop in calendar.days[this.day.date].users) {
              if (calendar.days[this.day.date].users[prop].status == "declined")
                all = false;
            }
            if (all && dayInterference.status != "agreed")
              dayInterference = { status: "possible", calendar: calendar.name };
            if (
              all &&
              Object.keys(calendar.users).length ===
                Object.keys(calendar.days[this.day.date].users).length
            )
              dayInterference = { status: "agreed", calendar: calendar.name };
          }
        });
        return dayInterference;
      },
    },
  };
</script>
